import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import {
  MenuElement,
  Workspace,
} from 'frontend-container/components/Menu/types';
import {
  MAC_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX,
} from 'frontend-container/shared/constants';

import {
  FeatureToggleName,
  MeetingsAndEventsCustomerSettingsKeys,
  propertyPermissionKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const meetingAndEventsPermissions = propertyPermissionKeys.meetingsAndEvents;

const MEETINGS_AND_EVENTS_MENU_ID = 'menu-meetings-and-events';

const BOOKING_LINK = '/itinerary/integrated-booking/booking/:id';
const MEETINGS_EVENTS_BOOKING_LINK = '/meetings-and-events/booking/:id';
const MEETINGS_EVENTS_PACKAGE_LINK = '/meetings-and-events/package';
const CHANGELOG_LINK = '/changelog/changelog';

const BOOKING_DASHBOARD_LINK = 'meetings-and-events/booking-dashboard';
const PACKAGES_LINK = 'meetings-and-events/packages';
const EVENTS_DASHBOARD_LINK = 'meetings-and-events/events-dashboard';

const BOOKINGS_TITLE = 'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.BOOKINGS';
const PACKAGES_TITLE = 'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.PACKAGES';
const EVENTS_DASHBOARD_TITLE =
  'MENU.MEETINGS_AND_EVENTS.BREADCRUMB.4SEGMENT.EVENTS_DASHBOARD';

export const meetingsAndEventsMenu: MenuElement = {
  workspaceType: () => Workspace.PROPERTY,
  translation: 'MENU.MEETINGS_AND_EVENTS.TITLE',
  icon: IconName.conferences,
  id: MEETINGS_AND_EVENTS_MENU_ID,
  permissionsConfiguration: {
    permissions: [
      {
        key: meetingAndEventsPermissions.appAccess,
        source: AccessSource.Property,
      },
    ],
    permissionsConjunction: PermissionsConjunction.And,
  },
  settings: [
    {
      key: MeetingsAndEventsCustomerSettingsKeys.MeetingsAndEventsModuleActivation,
      source: AccessSource.Property,
    },
  ],
  featureToggles: [
    {
      key: FeatureToggleName.MeetingsAndEventsModule,
      source: AccessSource.Property,
    },
  ],
  items: [
    {
      link: `${acConfig.newFrontendUrls.meetingsAndEvents}/booking-dashboard`,
      code: 'MeetingAndEventsBookingDashboard',
      id: `${MEETINGS_AND_EVENTS_MENU_ID}-booking-dashboard`,
      aliases: [
        acConfig.newFrontendUrls.meetingsAndEvents,
        `${acConfig.newFrontendUrls.meetingsAndEvents}/booking`,
        `${acConfig.newFrontendUrls.itinerary}/integrated-booking/booking`,
      ],
      translation: 'MENU.MEETINGS_AND_EVENTS.ITEMS.BOOKINGS_DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: meetingAndEventsPermissions.booking.viewDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}B`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}B`,
      keyCode: 66,
    },
    {
      link: `${acConfig.newFrontendUrls.meetingsAndEvents}/events-dashboard`,
      id: `${MEETINGS_AND_EVENTS_MENU_ID}-events-dashboard`,
      code: 'MeetingAndEventsEventDashboard',
      translation: 'MENU.MEETINGS_AND_EVENTS.ITEMS.EVENTS_DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: meetingAndEventsPermissions.event.viewDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}N`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}N`,
      keyCode: 78,
    },
    {
      link: `${acConfig.newFrontendUrls.meetingsAndEvents}/conference-diary`,
      code: 'ConferenceDiary',
      id: `${MEETINGS_AND_EVENTS_MENU_ID}-conference-diary`,
      translation: 'MENU.MEETINGS_AND_EVENTS.ITEMS.CONFERENCE_DIARY',
      permissionsConfiguration: {
        permissions: [
          {
            key: meetingAndEventsPermissions.event.viewDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}8`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}8`,
      keyCode: 56,
    },
    {
      link: `${acConfig.newFrontendUrls.meetingsAndEvents}/booking/create`,
      translation: 'MENU.MEETINGS_AND_EVENTS.ITEMS.BOOKING_NEW',
      id: `${MEETINGS_AND_EVENTS_MENU_ID}-create-booking`,
      permissionsConfiguration: {
        permissions: [
          {
            key: meetingAndEventsPermissions.booking.create,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.meetingsAndEvents}/waitlist-dashboard`,
      id: `${MEETINGS_AND_EVENTS_MENU_ID}-waitlist-dashboard`,
      code: 'WaitlistDashboard',
      translation: 'MENU.MEETINGS_AND_EVENTS.ITEMS.WAITLIST_DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: meetingAndEventsPermissions.event.viewDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      featureToggles: [
        {
          key: FeatureToggleName.WaitlistPriority,
          source: AccessSource.Property,
        },
      ],
    },
    {
      link: `${acConfig.newFrontendUrls.meetingsAndEvents}/function-space-rate-manager`,
      translation: 'MENU.MEETINGS_AND_EVENTS.ITEMS.FUNCTION_SPACE_RATE_MANAGER',
      id: `${MEETINGS_AND_EVENTS_MENU_ID}-space-rate-manager`,
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}9`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}9`,
      keyCode: 57,
    },
    {
      link: `${acConfig.newFrontendUrls.meetingsAndEvents}/packages`,
      id: `${MEETINGS_AND_EVENTS_MENU_ID}-packages`,
      settings: [
        {
          key: MeetingsAndEventsCustomerSettingsKeys.Packages,
          source: AccessSource.Property,
        },
      ],
      translation: 'MENU.MEETINGS_AND_EVENTS.ITEMS.PACKAGES',
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}7`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}7`,
      keyCode: 55,
    },
    {
      link: `${acConfig.newFrontendUrls.meetingsAndEvents}/date-range-function-sheet-dashboard`,
      id: `${MEETINGS_AND_EVENTS_MENU_ID}-date-range-function-sheet-dashboard`,
      translation:
        'MENU.MEETINGS_AND_EVENTS.ITEMS.DATE_RANGE_FUNCTION_SHEET_DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: meetingAndEventsPermissions.event.viewDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
  ],
  breadcrumbs4thSegment: [
    [
      `${BOOKING_LINK}/event-details/:id`,
      { link: BOOKING_DASHBOARD_LINK, title: BOOKINGS_TITLE },
    ],
    [
      `${BOOKING_LINK}/booking-details`,
      { link: BOOKING_DASHBOARD_LINK, title: BOOKINGS_TITLE },
    ],
    [
      `${BOOKING_LINK}/meetings-and-events-details`,
      { link: BOOKING_DASHBOARD_LINK, title: BOOKINGS_TITLE },
    ],
    [
      `${BOOKING_LINK}/package`,
      { link: BOOKING_DASHBOARD_LINK, title: BOOKINGS_TITLE },
    ],
    [
      `${BOOKING_LINK}/events-overview`,
      { link: BOOKING_DASHBOARD_LINK, title: BOOKINGS_TITLE },
    ],
    [
      `${BOOKING_LINK}/me-sales-activities`,
      { link: BOOKING_DASHBOARD_LINK, title: BOOKINGS_TITLE },
    ],
    [
      `${BOOKING_LINK}/group-details`,
      { link: BOOKING_DASHBOARD_LINK, title: BOOKINGS_TITLE },
    ],
    [
      `${BOOKING_LINK}/room-grid`,
      { link: BOOKING_DASHBOARD_LINK, title: BOOKINGS_TITLE },
    ],
    [
      `${BOOKING_LINK}/rooming-list`,
      { link: BOOKING_DASHBOARD_LINK, title: BOOKINGS_TITLE },
    ],
    [
      `${BOOKING_LINK}/group-sales-activities`,
      { link: BOOKING_DASHBOARD_LINK, title: BOOKINGS_TITLE },
    ],
    [
      `${BOOKING_LINK}/create-event`,
      { link: BOOKING_DASHBOARD_LINK, title: BOOKINGS_TITLE },
    ],

    [
      `${MEETINGS_EVENTS_PACKAGE_LINK}/create`,
      { link: PACKAGES_LINK, title: PACKAGES_TITLE },
    ],
    [
      `${MEETINGS_EVENTS_PACKAGE_LINK}/:id`,
      { link: PACKAGES_LINK, title: PACKAGES_TITLE },
    ],

    [
      `${MEETINGS_EVENTS_BOOKING_LINK}/events-overview`,
      { link: BOOKING_DASHBOARD_LINK, title: BOOKINGS_TITLE },
    ],
    [
      `${MEETINGS_EVENTS_BOOKING_LINK}/packages`,
      { link: BOOKING_DASHBOARD_LINK, title: BOOKINGS_TITLE },
    ],
    [
      `${MEETINGS_EVENTS_BOOKING_LINK}/event-details/:id`,
      { link: BOOKING_DASHBOARD_LINK, title: BOOKINGS_TITLE },
    ],
    [
      `${MEETINGS_EVENTS_BOOKING_LINK}/sales-activities`,
      { link: BOOKING_DASHBOARD_LINK, title: BOOKINGS_TITLE },
    ],
    [
      `${MEETINGS_EVENTS_BOOKING_LINK}/booking-details`,
      { link: BOOKING_DASHBOARD_LINK, title: BOOKINGS_TITLE },
    ],
    [
      `${MEETINGS_EVENTS_BOOKING_LINK}/create-event`,
      { link: BOOKING_DASHBOARD_LINK, title: BOOKINGS_TITLE },
    ],

    [
      `${CHANGELOG_LINK}?Booking`,
      { link: BOOKING_DASHBOARD_LINK, title: BOOKINGS_TITLE },
    ],
    [
      `${CHANGELOG_LINK}?Event`,
      { link: EVENTS_DASHBOARD_LINK, title: EVENTS_DASHBOARD_TITLE },
    ],
  ],
};
