import { useCallback } from 'react';
import { useSessionContextTypeIds } from 'frontend-container/components/Menu/components/Context/useSessionContextTypeIds';
import { getCroContextById } from 'frontend-container/components/Menu/components/CroContext/service';
import { getProfileCenterContextById } from 'frontend-container/components/Menu/components/ProfileCentersContext/service';
import {
  getPropertyContextById,
  getPropertyContexts,
} from 'frontend-container/components/Menu/components/PropertyContext/service';
import { Workspace } from 'frontend-container/components/Menu/types';
import { getInitialContext } from 'frontend-container/components/Menu/utils/selectInitialContext';
import { useRouterContext } from 'frontend-container/components/Router/context/context';
import { getCurrentWorkspaceType } from 'frontend-container/components/Router/utils/getCurrentContextType';

import { LoginService, SessionService } from '@ac/library-utils/dist/services';
import { isDefined } from '@ac/library-utils/dist/utils';

export const useRefreshContextForRouter = (): (() => void) => {
  const { propertyId, centralReservationOfficeId, profileCenterId } =
    useSessionContextTypeIds();
  const setSelectedProperty = useRouterContext(
    (store) => store.setSelectedProperty
  );
  const setSelectedCro = useRouterContext((store) => store.setSelectedCro);
  const setSelectedProfileCenter = useRouterContext(
    (store) => store.setSelectedProfileCenter
  );
  const setCurrentUnit = useRouterContext((store) => store.setCurrentUnit);

  const refreshContext = useCallback((): void => {
    const tenantId = LoginService.authData()?.tenantId;

    if (!LoginService.isSuperUser() && !tenantId) {
      throw new Error('Tenant ID is undefined');
    }

    const contextType = getCurrentWorkspaceType();

    const propertyContext = isDefined(propertyId)
      ? getPropertyContextById(propertyId)
      : undefined;

    const croContext = isDefined(centralReservationOfficeId)
      ? getCroContextById(centralReservationOfficeId)
      : undefined;

    const profileCenterContext = isDefined(profileCenterId)
      ? getProfileCenterContextById(profileCenterId)
      : undefined;

    if (propertyContext) {
      setSelectedProperty(propertyContext);
    }

    if (croContext) {
      setSelectedCro(croContext);
    }

    if (profileCenterContext) {
      setSelectedProfileCenter(profileCenterContext);
    }

    switch (contextType) {
      case Workspace.PROPERTY: {
        setCurrentUnit(propertyContext);
        break;
      }
      case Workspace.CRO: {
        setCurrentUnit(croContext);

        break;
      }
      case Workspace.PROFILE_CENTER: {
        setCurrentUnit(profileCenterContext);

        break;
      }
      default: {
        const propertyContexts = getPropertyContexts();

        const context = getInitialContext(
          propertyContexts,
          SessionService.getPropertyId()
        );

        setSelectedProperty(context);
        setCurrentUnit(context);

        break;
      }
    }
  }, [
    centralReservationOfficeId,
    profileCenterId,
    propertyId,
    setCurrentUnit,
    setSelectedCro,
    setSelectedProfileCenter,
    setSelectedProperty,
  ]);

  return refreshContext;
};
