import { DeploymentRegionType } from '@ac/library-api';
import { IconName } from '@ac/web-components';

export enum ContextType {
  PROPERTY = 'Property',
  CRO = 'CentralReservationOffice',
  PROFILE_CENTER = 'ProfileCenter',
}
export interface Context {
  id: string;
  code: string;
  name?: string;
  regionCode?: string;
  deploymentRegionType?: DeploymentRegionType;
  type: ContextType;
  icon: IconName;
}
