import { Workspace } from 'frontend-container/components/Menu/types';
import { isNotificationsTenantContextDashboard } from 'frontend-container/components/Menu/utils/modules/notifications';
import { getSelectedMainMenuItem } from 'frontend-container/components/Router/utils/getSelectedMainMenuItem';

export const getCurrentWorkspaceType = (): Workspace | undefined => {
  if (isNotificationsTenantContextDashboard()) {
    return Workspace.CONFIGURATION;
  }

  const { element } = getSelectedMainMenuItem();

  return element?.workspaceType();
};
