import {
  MenuElement,
  Workspace,
} from 'frontend-container/components/Menu/types';

export const getCustomMenuElementConfig = (): Pick<
  MenuElement,
  'workspaceType'
> => ({
  workspaceType: (): Workspace => Workspace.NONE,
});
