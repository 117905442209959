import { isCampaignManagementEnabled } from 'frontend-container/components/Menu/components/ProfileCentersContext/service';
import { profileCenterMenu } from 'frontend-container/components/Menu/configuration/profileCenterModules/profileCenters';
import { propertyUsers } from 'frontend-container/components/Menu/configuration/propertyModules/propertyUsers';
import { inquiryMenu } from 'frontend-container/components/Menu/configuration/propertyModules/reservations/inquiry';
import { salesActivitiesMenu } from 'frontend-container/components/Menu/configuration/propertyModules/taskManagement/salesActivities';
import { taskManagementMenu } from 'frontend-container/components/Menu/configuration/propertyModules/taskManagement/taskManagement';
import { travelAgentCommissionsMenu } from 'frontend-container/components/Menu/configuration/propertyModules/travelAgentComissions';

const MEGA_MENU_ELEMENTS_WITH_SINGLE_OPTION = [
  inquiryMenu.id,
  travelAgentCommissionsMenu.id,
  taskManagementMenu.id,
  salesActivitiesMenu.id,
  propertyUsers.id,
];

export const getMegaMenuElementsWithSingleOption = (): string[] => {
  const elementsArray = [];

  if (!isCampaignManagementEnabled()) {
    elementsArray.push(profileCenterMenu.id);
  }

  return [...MEGA_MENU_ELEMENTS_WITH_SINGLE_OPTION, ...elementsArray];
};
