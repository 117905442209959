import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import {
  MenuElement,
  Workspace,
} from 'frontend-container/components/Menu/types';
import { hasAnyCroContextGuard } from 'frontend-container/components/Menu/utils/modules/centralReservationOffice';

import {
  centralReservationOfficePermissionKeys,
  GeneralCustomerSettingsKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const RESERVATIONS_MENU_ID = 'menu-reservations-lookup';

export const reservationsLookupMenu: MenuElement = {
  workspaceType: () => Workspace.CRO,
  translation: 'MENU.RESERVATIONS_LOOKUP.TITLE',
  icon: IconName.reservations,
  id: RESERVATIONS_MENU_ID,
  settings: [
    {
      key: GeneralCustomerSettingsKeys.CentralReservationOffice,
      source: AccessSource.CentralReservationOffice,
    },
  ],
  items: [
    {
      link: `${acConfig.newFrontendUrls.centralReservationOffice}/reservations-lookup`,
      translation: 'MENU.RESERVATIONS_LOOKUP.ITEMS.DASHBOARD',
      id: `${RESERVATIONS_MENU_ID}-dashboard`,
      permissionsConfiguration: {
        permissions: [
          {
            key: centralReservationOfficePermissionKeys.useReservationLookup,
            source: AccessSource.CentralReservationOffice,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      allowedWhen: hasAnyCroContextGuard,
      code: 'ReservationLookup',
    },
  ],
};
