import {
  logAlternateDomainsNotFoundForRegion,
  logAlternateDomainSwitchingError,
  logDomainsSpeedTestFinished,
  logDomainsVerificationStarted,
} from 'frontend-container/components/AlternateDomainSwitching/logging/alternateDomainSwitchingLogger';
import { DomainInfo } from 'frontend-container/components/AlternateDomainSwitching/types/domainInfo';
import { AlternateDomainsSpeedTestConfig } from 'frontend-container/components/AlternateDomainSwitching/verification/configuration/testConfig';
import { getCurrentRegionDomains } from 'frontend-container/components/AlternateDomainSwitching/verification/region/getCurrentRegionDomains';
import { verifyDomainsDownloadSpeed } from 'frontend-container/components/AlternateDomainSwitching/verification/speedTest/verifyDomainsDownloadSpeed';

interface Result {
  fasterDomainThanCurrent?: DomainInfo;
}

interface Params {
  config: AlternateDomainsSpeedTestConfig;
}

export const proceedAlternateDomainsSpeedTest = async ({
  config,
}: Params): Promise<Result> => {
  try {
    logDomainsVerificationStarted();

    const regionDomains = getCurrentRegionDomains();

    if (!regionDomains?.alternateDomains?.length) {
      logAlternateDomainsNotFoundForRegion();

      return {};
    }

    const speedResult = await verifyDomainsDownloadSpeed({
      domains: regionDomains,
      config,
    });

    logDomainsSpeedTestFinished(speedResult);

    return {
      fasterDomainThanCurrent: speedResult?.fasterDomainThanCurrent?.domain,
    };
  } catch (error: unknown) {
    logAlternateDomainSwitchingError({
      message:
        'Error caught in try/catch block in proceedAlternateDomainsSpeedTest',
      data: error,
    });

    return {};
  }
};
