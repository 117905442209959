import {
  isCroLandingPage,
  isLandingPage,
  isProfileCenterLandingPage,
  landingPageDestination,
} from 'frontend-container/components/LandingPage/location';
import { getAllAccessConfiguration } from 'frontend-container/components/Menu/authorization/accessConfiguration';
import { getAllowedMenuItems } from 'frontend-container/components/Menu/authorization/getAllowedMenuItems';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import {
  CentralReservationOfficeDefaultLandingPage,
  ProfileCenterDefaultLandingPage,
  SystemDefaultLandingPage,
} from 'frontend-container/components/Menu/components/User/UserPreferences/configuration';
import {
  getAllowedMainApplicationMenu,
  getFullMenu,
} from 'frontend-container/components/Menu/configuration';
import { centralAvailabilityInquiryMenu } from 'frontend-container/components/Menu/configuration/croModules/centralAvailabilityInquiry';
import { reservationsLookupMenu } from 'frontend-container/components/Menu/configuration/croModules/reservationsLookup';
import { profileCenterMenu } from 'frontend-container/components/Menu/configuration/profileCenterModules/profileCenters';
import { customersDashboard } from 'frontend-container/components/Menu/configuration/tenantModules/configuration/systemUser/customers';
import { findSelectedMenuElement } from 'frontend-container/components/Menu/menuItems';

import { UserPreferences } from '@ac/library-api';

export const processLandingPagePathname = (urlString: string): string => {
  const url = new URL(urlString);
  const pathname = url.pathname;

  if (!isLandingPage(pathname)) {
    return pathname;
  }

  const destination = new URLSearchParams(url.search).get(
    landingPageDestination
  );

  if (destination) {
    const allowedMenuElements = getAllowedMenuItems(
      getFullMenu(),
      getAllAccessConfiguration()
    );
    const { item: selectedItem } = findSelectedMenuElement(
      allowedMenuElements,
      { customPathname: destination }
    );

    if (selectedItem) {
      return selectedItem.link;
    }
  }

  const { userDetails, userPreferences } = userService.getFullCurrentUser();

  if (userDetails.isSystemUser) {
    return getDefaultSystemLandingPageRoute();
  }

  return isCroLandingPage(pathname)
    ? getDefaultCROLandingPageRoute(userPreferences)
    : isProfileCenterLandingPage(pathname)
      ? getDefaultProfileCenterLandingPageRoute(userPreferences)
      : getDefaultPropertyLandingPageRoute(userPreferences);
};

const getDefaultSystemLandingPageRoute = (): string => {
  return customersDashboard.link;
};

const getDefaultPropertyLandingPageRoute = (
  userPreferences: UserPreferences | undefined
): string => {
  const defaultLandingPageCode =
    userPreferences?.preferences?.defaultLandingScreen ||
    SystemDefaultLandingPage.code;

  const allowedMenuElements = getAllowedMainApplicationMenu(
    getAllAccessConfiguration()
  ).flatMap((menuElement) => menuElement.items);

  const relatedItem = allowedMenuElements.find(
    (item) => item.code === defaultLandingPageCode
  );

  return (
    relatedItem?.link ??
    SystemDefaultLandingPage.pathname ??
    allowedMenuElements[0]?.link
  );
};

const getDefaultProfileCenterLandingPageRoute = (
  userPreferences: UserPreferences | undefined
): string => {
  const defaultLandingPageCode =
    userPreferences?.preferences?.defaultProfileCenterLandingScreen ||
    ProfileCenterDefaultLandingPage.code;

  const menuElements = [profileCenterMenu];

  const allAccessConfiguration = getAllAccessConfiguration();
  const allowedMenuElements = getAllowedMenuItems(
    menuElements,
    allAccessConfiguration
  ).flatMap((menuElement) => menuElement.items);

  const relatedItem = allowedMenuElements.find(
    (item) => item.code === defaultLandingPageCode
  );

  return (
    relatedItem?.link ??
    allowedMenuElements[0]?.link ??
    ProfileCenterDefaultLandingPage.pathname
  );
};

const getDefaultCROLandingPageRoute = (
  userPreferences: UserPreferences | undefined
): string => {
  const defaultLandingPageCode =
    userPreferences?.preferences
      ?.defaultCentralReservationOfficeLandingScreen ||
    CentralReservationOfficeDefaultLandingPage.code;

  /** Custom menu elements to exclude configuration links as we don't want to change context */
  const menuElements = [centralAvailabilityInquiryMenu, reservationsLookupMenu];

  const allAccessConfiguration = getAllAccessConfiguration();
  const allowedMenuElements = getAllowedMenuItems(
    menuElements,
    allAccessConfiguration
  ).flatMap((menuElement) => menuElement.items);

  const relatedItem = allowedMenuElements.find(
    (item) => item.code === defaultLandingPageCode
  );

  return (
    relatedItem?.link ??
    allowedMenuElements[0]?.link ??
    CentralReservationOfficeDefaultLandingPage.pathname
  );
};
