import { useCallback, useMemo } from 'react';
import {
  croLandingPagePathname,
  propertyLandingPagePathname,
} from 'frontend-container/components/LandingPage';
import { profileCenterLandingPagePathname } from 'frontend-container/components/LandingPage/location';
import { getAllowedMenuItems } from 'frontend-container/components/Menu/authorization/getAllowedMenuItems';
import { isConfigurationEnabled } from 'frontend-container/components/Menu/components/ConfigurationContext/service';
import { Context } from 'frontend-container/components/Menu/components/Context/index';
import { getCroContexts } from 'frontend-container/components/Menu/components/CroContext/service';
import { getProfileCentersContexts } from 'frontend-container/components/Menu/components/ProfileCentersContext/service';
import { getPropertyContexts } from 'frontend-container/components/Menu/components/PropertyContext/service';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import { getAllowedCentralReservationOfficeMenu } from 'frontend-container/components/Menu/configuration';
import { configurationMenu } from 'frontend-container/components/Menu/configuration/multiContextModules/configuration';
import {
  MenuElement,
  MenuElementItem,
  Workspace,
  WorkspaceWithMenuItems,
} from 'frontend-container/components/Menu/types';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/utils/isMenuV2Enabled';
import { useRouterContext } from 'frontend-container/components/Router/context/context';
import { getCurrentWorkspaceType } from 'frontend-container/components/Router/utils/getCurrentContextType';
import { navigateToPage } from 'frontend-container/shared/navigation/navigateToPage';

import { acConfig } from '@ac/library-utils/dist/declarations';
import { isDefined } from '@ac/library-utils/dist/utils';
import { IconName } from '@ac/web-components';

export type WorkspaceItem = {
  id: WorkspaceWithMenuItems;
  translation: string;
  icon: IconName;
  onClick: () => void;
  getWorkspaceUnits: (
    currentButtonOption?: WorkspaceItem
  ) => Context[] | undefined;
  url: string;
  subMenuElements?: MenuElementItem[];
  unitName?: string;
};

export type WorkspaceElement = {
  [key in WorkspaceWithMenuItems]: {
    workspaceItem: WorkspaceItem;
    guard: boolean;
  };
};

export type WorkspaceItems = {
  workspaceElement: WorkspaceElement;
  workspaceItems: WorkspaceItem[];
  currentWorkspaceOption?: WorkspaceItem;
};

type UseContextMenuItems = {
  workspaceMenuItems: WorkspaceElement;
  visibleWorkspaceMenuItems: WorkspaceItem[];
  currentWorkspaceItem: WorkspaceItem | undefined;
  getWorkspaceContextUnits: (
    workspace: WorkspaceWithMenuItems
  ) => Context[] | undefined;
};

const getMenuElementItems = (
  menuElements: MenuElement[],
  useMenuElementLabel?: boolean
): MenuElementItem[] => {
  if (useMenuElementLabel) {
    return menuElements.flatMap((element) =>
      element.items.map((item) => ({
        ...item,
        translation: element.translation,
      }))
    );
  }

  return menuElements.flatMap((element) => element.items);
};

export const useWorkspaceMenuItems = (): UseContextMenuItems => {
  const selectedCro = useRouterContext((store) => store.selectedCro);
  const selectedProfileCenter = useRouterContext(
    (store) => store.selectedProfileCenter
  );
  const selectedProperty = useRouterContext((store) => store.selectedProperty);

  const croGuard = useMemo((): boolean => {
    return isDefined(selectedCro);
  }, [selectedCro]);

  const accessConfiguration = useRouterContext(
    (store) => store.allAccessConfiguration
  );

  const currentWorkspace = getCurrentWorkspaceType();

  const croMenuSubItems = useMemo(
    (): MenuElementItem[] =>
      accessConfiguration
        ? getMenuElementItems(
            getAllowedCentralReservationOfficeMenu(accessConfiguration),
            true
          )
        : [],
    [accessConfiguration]
  );

  const configurationSubItemsMenu = useMemo(
    (): MenuElementItem[] =>
      accessConfiguration
        ? getMenuElementItems(
            getAllowedMenuItems([configurationMenu], accessConfiguration)
          )
        : [],
    [accessConfiguration]
  );

  const propertyGuard = useMemo((): boolean => {
    return isDefined(selectedProperty);
  }, [selectedProperty]);

  const profileCenterGuard = useMemo((): boolean => {
    return isDefined(selectedProfileCenter);
  }, [selectedProfileCenter]);

  const configurationGuard = (): boolean => {
    return isConfigurationEnabled();
  };

  const workspaceMenuItems: WorkspaceElement = useMemo(
    () => ({
      [Workspace.PROPERTY]: {
        workspaceItem: {
          id: Workspace.PROPERTY,
          translation: 'MENU.CONTEXT.SWITCH_TO_PROPERTY',
          icon: IconName.propertyZone,
          url: propertyLandingPagePathname,
          unitName: selectedProperty?.code,
          getWorkspaceUnits: (): Context[] => getPropertyContexts(),
          onClick: (): void => navigateToPage(propertyLandingPagePathname),
        },
        guard: propertyGuard,
      },
      [Workspace.PROFILE_CENTER]: {
        workspaceItem: {
          id: Workspace.PROFILE_CENTER,
          translation: 'MENU.CONTEXT.SWITCH_TO_PROFILE_CENTER',
          icon: IconName.profileDetails,
          unitName: selectedProfileCenter?.code,
          url: profileCenterLandingPagePathname,
          getWorkspaceUnits: (): Context[] => getProfileCentersContexts(),
          onClick: (): void => navigateToPage(profileCenterLandingPagePathname),
        },
        guard: profileCenterGuard,
      },
      [Workspace.CRO]: {
        workspaceItem: {
          id: Workspace.CRO,
          translation: 'MENU.CONTEXT.SWITCH_TO_CRO',
          icon: IconName.cro,
          url: croLandingPagePathname,
          unitName: selectedCro?.code,
          onClick: (): void => navigateToPage(croLandingPagePathname),
          getWorkspaceUnits: (): Context[] => getCroContexts(),
          subMenuElements: croMenuSubItems,
        },
        guard: croGuard,
      },
      [Workspace.CONFIGURATION]: {
        workspaceItem: {
          id: Workspace.CONFIGURATION,
          translation: 'MENU.CONTEXT.SWITCH_TO_CONFIGURATION',
          icon: IconName.settings,
          url: acConfig.newFrontendUrls.configurationv2,
          getWorkspaceUnits: (): Context[] => [],
          onClick: (): void =>
            navigateToPage(acConfig.newFrontendUrls.configurationv2),
          subMenuElements: configurationSubItemsMenu,
        },
        guard: configurationGuard(),
      },
    }),
    [
      configurationSubItemsMenu,
      croGuard,
      croMenuSubItems,
      profileCenterGuard,
      propertyGuard,
      selectedCro?.code,
      selectedProfileCenter?.code,
      selectedProperty?.code,
    ]
  );

  const visibleWorkspaceMenuItems = useMemo(
    () =>
      Object.values(workspaceMenuItems).reduce<WorkspaceItem[]>(
        (elements, element) => {
          if (element.guard) {
            return [...elements, { ...element.workspaceItem }];
          }

          return elements;
        },
        []
      ),
    [workspaceMenuItems]
  );

  const currentWorkspaceItem = useMemo(() => {
    // in case of error page or unknown path, display default workspace or first available workspace
    if (currentWorkspace === undefined) {
      if (!getIsMenuV2Enabled()) {
        return visibleWorkspaceMenuItems[0];
      }

      return (
        visibleWorkspaceMenuItems.find(
          (item) =>
            item.id ===
            userService.getCurrentUserPreferences()?.preferences
              ?.defaultWorkspace
        ) || visibleWorkspaceMenuItems[0]
      );
    }

    return visibleWorkspaceMenuItems.find(
      (item) => item.id === currentWorkspace
    );
  }, [currentWorkspace, visibleWorkspaceMenuItems]);

  const getWorkspaceContextUnits = useCallback(
    (workspace: WorkspaceWithMenuItems) => {
      return workspaceMenuItems[workspace].workspaceItem.getWorkspaceUnits();
    },
    [workspaceMenuItems]
  );

  return {
    workspaceMenuItems,
    visibleWorkspaceMenuItems,
    currentWorkspaceItem,
    getWorkspaceContextUnits,
  };
};
