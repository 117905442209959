import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import {
  MenuElement,
  Workspace,
} from 'frontend-container/components/Menu/types';

import { propertyPermissionKeys } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const WORKFLOWS_MENU_ID = 'menu-workflows';

export const workflowsMenu: MenuElement = {
  workspaceType: () => Workspace.PROPERTY,
  translation: 'MENU.WORKFLOWS.TITLE',
  icon: IconName.rulesGeneric,
  id: WORKFLOWS_MENU_ID,
  hideInGlobalSearch: true,
  permissionsConfiguration: {
    permissions: [
      {
        key: propertyPermissionKeys.workflows.view,
        source: AccessSource.Property,
      },
    ],
    permissionsConjunction: PermissionsConjunction.Or,
  },
  items: [
    {
      link: `${acConfig.newFrontendUrls.workflows}`,
      translation: 'MENU.WORKFLOWS.ITEMS.DASHBOARD',
      id: `${WORKFLOWS_MENU_ID}-dashboard`,
    },
    {
      link: `${acConfig.newFrontendUrls.workflows}/programs`,
      translation: 'MENU.WORKFLOWS.ITEMS.PROGRAMS',
      id: `${WORKFLOWS_MENU_ID}-programs`,
    },
    {
      link: `${acConfig.newFrontendUrls.workflows}/processes`,
      id: `${WORKFLOWS_MENU_ID}-processes`,
      translation: 'MENU.WORKFLOWS.ITEMS.PROCESSES',
    },
  ],
};
