import { DomainsSpeedTestResult } from 'frontend-container/components/AlternateDomainSwitching/verification/speedTest/domainsSpeedTestResult';
import { KeysForOverriddenLogLevel } from 'frontend-container/utils/logger/configurationLogKeys';
import { getInstance } from 'frontend-container/utils/logger/logger';

import { LogDetails } from '@ac/library-utils/dist/declarations';

enum AlternateDomainSwitchingLogCode {
  Error = 'AlternateDomainSwitchingError',
  AlternateDomainsVerificationStarted = 'AlternateDomainsVerificationStarted',
  AlternateDomainsNotFoundForRegion = 'AlternateDomainsNotFoundForRegion',
  AlternateDomainsSpeedTestFinished = 'AlternateDomainsSpeedTestFinished',
  SpeedTestDomainApiCallResult = 'SpeedTestDomainApiCallResult',
  RedirectToFasterDomainScheduled = 'RedirectToFasterDomainScheduled',
  AutomaticRedirectToFasterDomain = 'AutomaticRedirectToFasterDomain',
  ManualRedirectToPreviousDomain = 'ManualRedirectToPreviousDomain',
}

export const logDomainsVerificationStarted = (): void =>
  logAlternateDomainSwitchingDebugInfo({
    code: AlternateDomainSwitchingLogCode.AlternateDomainsVerificationStarted,
    message:
      'Feature is enabled for tenant/property, the verification process starts.',
  });

export const logAlternateDomainsNotFoundForRegion = (): void =>
  logAlternateDomainSwitchingDebugInfo({
    code: AlternateDomainSwitchingLogCode.AlternateDomainsNotFoundForRegion,
    message:
      'Stop alternate domains verification - alternate domains have not been found for region.',
  });

export const logSpeedTestDomainApiCallResult = <TData>(
  log: Omit<AlternateDomainSwitchingLog<TData>, 'code'>
): void =>
  logAlternateDomainSwitchingDebugInfo({
    code: AlternateDomainSwitchingLogCode.SpeedTestDomainApiCallResult,
    ...log,
  });

export const logDomainsSpeedTestFinished = (
  speedTestResult: DomainsSpeedTestResult
): void => {
  const resultMessage = speedTestResult.fasterDomainThanCurrent
    ? `FASTER DOMAIN FOUND: ${speedTestResult.fasterDomainThanCurrent.domain.web}`
    : 'Faster domain has not been found';

  const successCount = speedTestResult.alternateDomainsSpeeds.length + 1;
  const failsCount = speedTestResult.failedDomainTestResults.length;

  logAlternateDomainSwitchingDebugInfo({
    code: AlternateDomainSwitchingLogCode.AlternateDomainsSpeedTestFinished,
    message: `Speed test finished. Success in ${successCount}, fails in ${failsCount} domains. ${resultMessage}`,
    data: speedTestResult,
  });

  if (failsCount > 0) {
    logAlternateDomainSwitchingError({
      message: 'Some of tested domains were not verified because of error',
      data: speedTestResult.failedDomainTestResults,
    });
  }
};

export const logRedirectToFasterDomainScheduled = (
  fasterDomainUrl: string
): void =>
  logAlternateDomainSwitchingDebugInfo({
    code: AlternateDomainSwitchingLogCode.RedirectToFasterDomainScheduled,
    message: `Waiting for redirect to ${fasterDomainUrl}`,
  });

export const logRedirectToFasterDomain = async (
  targetDomainUrl: string
): Promise<void> => {
  logAlternateDomainSwitchingDebugInfo({
    code: AlternateDomainSwitchingLogCode.AutomaticRedirectToFasterDomain,
    message: `Redirect to ${targetDomainUrl} done`,
  });

  getInstance()?.forceSend();
};

export const logRedirectToPreviousDomain = (): void =>
  logAlternateDomainSwitchingDebugInfo({
    code: AlternateDomainSwitchingLogCode.ManualRedirectToPreviousDomain,
    message: 'User decided to go back to previous slower domain',
  });

export const logAlternateDomainSwitchingError = <TData>(
  log: Omit<AlternateDomainSwitchingLog<TData>, 'code'>
): void => {
  getInstance()?.error(
    mapLogToSerializableForm({
      code: AlternateDomainSwitchingLogCode.Error,
      ...log,
    }),
    {
      keyForOverriddenLogLevel:
        KeysForOverriddenLogLevel.AlternateDomainSwitching,
    }
  );
};

// Note: this type will be moved to library-utils and must be treated as an official log shape
interface LogDetailsV2 extends LogDetails {
  code: string;
  data?: string;
}

interface AlternateDomainSwitchingLog<TData = unknown> {
  code: AlternateDomainSwitchingLogCode;
  message?: string;
  data?: TData;
}

const logAlternateDomainSwitchingDebugInfo = <TData>(
  log: AlternateDomainSwitchingLog<TData>
): void => {
  getInstance()?.debug(mapLogToSerializableForm(log), {
    keyForOverriddenLogLevel:
      KeysForOverriddenLogLevel.AlternateDomainSwitching,
  });
};

const mapLogToSerializableForm = <TData>(
  log: AlternateDomainSwitchingLog<TData>
): LogDetailsV2 => ({
  code: log.code,
  message: log.message,
  data: mapDataToString(log.data),
});

const mapDataToString = <TData>(
  data: TData | undefined
): string | undefined => {
  if (!data) {
    return undefined;
  }

  if (typeof data === 'string') {
    return data;
  }

  if (data instanceof Error) {
    return JSON.stringify({
      errorMessage: data.message,
      errorData: data,
    });
  }

  return JSON.stringify(data);
};
