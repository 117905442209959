import { LocalStorage } from 'frontend-container/shared/storage/localStorage';

const STORAGE_RESULT_KEY = 'aboveCloud.alternateDomainsSpeedTestResult';

export interface AlternateDomainsSpeedTestData {
  results: {
    [userId: string]: {
      lastSpeedTestDateTime: string;
    };
  };
}

interface SetSpeedTestDateParams {
  userId: string;
  lastSpeedTestDateTime: string;
}

interface GetLastResultDateParams {
  userId: string;
}

export const setSpeedTestDateInLocalStorage = ({
  userId,
  lastSpeedTestDateTime,
}: SetSpeedTestDateParams): void => {
  const currentValue =
    LocalStorage.getParsed<AlternateDomainsSpeedTestData>(STORAGE_RESULT_KEY);
  const newValue: AlternateDomainsSpeedTestData = {
    results: {
      ...(currentValue?.results ?? {}),
      [userId]: { lastSpeedTestDateTime },
    },
  };

  LocalStorage.set(STORAGE_RESULT_KEY, newValue);
};

export const getLastSpeedTestDateTimeFromLocalStorage = ({
  userId,
}: GetLastResultDateParams): string | null => {
  const value =
    LocalStorage.getParsed<AlternateDomainsSpeedTestData>(STORAGE_RESULT_KEY);

  if (!value) {
    return null;
  }

  return value.results[userId]?.lastSpeedTestDateTime ?? null;
};
