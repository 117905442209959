import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllowedMenuItems } from 'frontend-container/components/Menu/authorization/getAllowedMenuItems';
import { GlobalSearchMenuElementItem } from 'frontend-container/components/Menu/components/GlobalSearchModal/types';
import { mapMenuElementsToGlobalSearchElement } from 'frontend-container/components/Menu/components/GlobalSearchModal/utils/mapMenuElementsToGlobalSearchElement';
import {
  getAllowedCentralReservationOfficeMenu,
  getAllowedProfileCenterMenu,
  getAllowedPropertyMenu,
  getAllowedSystemUserMenu,
} from 'frontend-container/components/Menu/configuration';
import { getConfigurationMenuInMainApplication } from 'frontend-container/components/Menu/configuration/multiContextModules/configuration';
import { Workspace } from 'frontend-container/components/Menu/types';
import { useRouterContext } from 'frontend-container/components/Router/context/context';
import { getCurrentWorkspaceType } from 'frontend-container/components/Router/utils/getCurrentContextType';

import { LoginService } from '@ac/library-utils/dist/services';

export interface AllowedItems {
  globalSearchItems: GlobalSearchMenuElementItem[];
}

export const useAllowedItems = (): AllowedItems => {
  const { t } = useTranslation();
  const currentWorkspace = getCurrentWorkspaceType();
  const accessConfiguration = useRouterContext(
    (store) => store.allAccessConfiguration
  );
  const selectedCro = useRouterContext((store) => store.selectedCro);
  const selectedProperty = useRouterContext((store) => store.selectedProperty);
  const selectedProfileCenter = useRouterContext(
    (store) => store.selectedProfileCenter
  );

  const propertySearchItems: GlobalSearchMenuElementItem[] = useMemo(() => {
    if (!selectedProperty) {
      return [];
    }
    const propertyItems = getAllowedPropertyMenu(accessConfiguration);

    const filteredPropertyItems = propertyItems?.filter(
      (item) => !item.hideInGlobalSearch
    );

    const badgeText =
      currentWorkspace !== Workspace.PROPERTY
        ? selectedProperty.code
        : undefined;

    return mapMenuElementsToGlobalSearchElement(
      filteredPropertyItems,
      t,
      badgeText
    );
  }, [accessConfiguration, currentWorkspace, selectedProperty, t]);

  const profileCenterSearchItems: GlobalSearchMenuElementItem[] =
    useMemo(() => {
      if (!selectedProfileCenter) {
        return [];
      }
      const allowedProfileCenterItems =
        getAllowedProfileCenterMenu(accessConfiguration);

      const badgeText =
        currentWorkspace !== Workspace.PROFILE_CENTER
          ? selectedProfileCenter?.code
          : undefined;

      return mapMenuElementsToGlobalSearchElement(
        allowedProfileCenterItems,
        t,
        badgeText
      );
    }, [accessConfiguration, currentWorkspace, selectedProfileCenter, t]);

  const croSearchItems: GlobalSearchMenuElementItem[] = useMemo(() => {
    if (!selectedCro) {
      return [];
    }
    const allowedCroItems =
      getAllowedCentralReservationOfficeMenu(accessConfiguration);

    const badgeText =
      currentWorkspace !== Workspace.CRO ? selectedCro?.code : undefined;

    return mapMenuElementsToGlobalSearchElement(allowedCroItems, t, badgeText);
  }, [accessConfiguration, currentWorkspace, selectedCro, t]);

  const configurationSearchItems: GlobalSearchMenuElementItem[] =
    useMemo(() => {
      const allowedConfigurationItems = LoginService.isSuperUser()
        ? getAllowedSystemUserMenu(accessConfiguration)
        : getAllowedMenuItems(
            [getConfigurationMenuInMainApplication()],
            accessConfiguration
          );

      return mapMenuElementsToGlobalSearchElement(allowedConfigurationItems, t);
    }, [accessConfiguration, t]);

  return {
    globalSearchItems: [
      ...propertySearchItems,
      ...profileCenterSearchItems,
      ...croSearchItems,
      ...configurationSearchItems,
    ],
  };
};
