import {
  Context,
  ContextType,
} from 'frontend-container/components/Menu/components/Context';
import { isContextRegionNonCurrent } from 'frontend-container/utils/region/isContextRegionNonCurrent';

import { SessionService } from '@ac/library-utils/dist/services';

export const setSessionContext = (
  context: Context | undefined,
  nextContextWillExistsInNewTab: boolean = false
): void => {
  if (!context || !context.id || nextContextWillExistsInNewTab) {
    return;
  }

  // We leave the current session data intact, because
  // New region = new domain, new domain = fresh localStorage/sessionStorage data
  if (isContextRegionNonCurrent(context)) {
    return;
  }

  if (context.type === ContextType.PROPERTY) {
    SessionService.setPropertyId(context.id);
  }

  if (context.type === ContextType.CRO) {
    SessionService.setCentralReservationOfficeId(context.id);
  }

  if (context.type === ContextType.PROFILE_CENTER) {
    SessionService.setProfileCenterId(context.id);
  }
};
