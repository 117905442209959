import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import {
  MenuElement,
  Workspace,
} from 'frontend-container/components/Menu/types';
import {
  getCustomerContextData,
  getPropertyContextData,
} from 'frontend-container/shared/businessContext/getBusinessContext';

import {
  ActivityReservationPropertySettingsKeys,
  activityReservationsCustomerPermissionKeys,
  ActivityReservationsCustomerSettingsKeys,
  activityReservationsPropertyPermissionKeys,
  FeatureToggleName,
  ItineraryCustomerSettingsKeys,
  itineraryPropertyPermissionKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const ACTIVITY_RESERVATIONS_MENU_ID = 'menu-activity-reservations';
export const ACTIVITIES_RESERVATIONS_DASHBOARD_LINK = `${acConfig.newFrontendUrls.activityReservations}/dashboard`;
export const ACTIVITY_RESERVATIONS_ICON = IconName.spa;
export const ACTIVITY_RESERVATIONS_TITLE = 'MENU.ACTIVITY_RESERVATIONS.TITLE';

const ACTIVITY_RESERVATION_LINK = `${acConfig.newFrontendUrls.activityReservations}/reservation`;
const ACTIVITY_RESERVATIONS_LINK = `${acConfig.newFrontendUrls.activityReservations}/reservations`;
const ACTIVITY_CONFIGURATION_LINK =
  acConfig.newFrontendUrls.activityConfiguration;

const ACTIVITY_CONFIGURATION_DASHBOARD_LINK = `${acConfig.newFrontendUrls.activityConfiguration}/dashboard`;
const ACTIVITY_MANAGER_LINK = `${acConfig.newFrontendUrls.activityConfiguration}/activity-manager`;
const PACKAGE_MANAGER_LINK = `${acConfig.newFrontendUrls.activityConfiguration}/package-manager`;

const ACTIVITIES_MANAGER_TITLE =
  'MENU.ACTIVITY_RESERVATIONS.BREADCRUMB.4SEGMENT.ACTIVITIES_MANAGER';
const ACTIVITIES_MANAGER_DASHBOARD_TITLE =
  'MENU.ACTIVITY_RESERVATIONS.BREADCRUMB.4SEGMENT.ACTIVITIES_MANAGER_DASHBOARD';
const ACTIVITIES_RATE_MANAGER_TITLE =
  'MENU.ACTIVITY_RESERVATIONS.BREADCRUMB.4SEGMENT.ACTIVITIES_RATE_MANAGER';
const ACTIVITIES_PACKAGE_MANAGER_TITLE =
  'MENU.ACTIVITY_RESERVATIONS.BREADCRUMB.4SEGMENT.ACTIVITIES_PACKAGE_MANAGER';

export const getActivityReservationsMenu = (): MenuElement => {
  const propertyContextData = getPropertyContextData();
  const customerContextData = getCustomerContextData();

  const propertySettings = propertyContextData
    ? propertyContextData?.settings.effectiveSettingsDetails
    : [];
  const customerSettings = customerContextData
    ? customerContextData?.settings.effectiveSettings
    : [];

  const settings = [...propertySettings, ...customerSettings];

  const hasPrestep = (
    settings.find(
      ({ code }) =>
        code ===
        ActivityReservationPropertySettingsKeys.ActivityReservationsUsePrestep
    )?.value as { value: boolean }
  )?.value;

  return {
    workspaceType: () => Workspace.PROPERTY,
    translation: ACTIVITY_RESERVATIONS_TITLE,
    icon: ACTIVITY_RESERVATIONS_ICON,
    id: ACTIVITY_RESERVATIONS_MENU_ID,
    featureToggles: [
      {
        key: FeatureToggleName.ActivityReservations,
        source: AccessSource.Property,
      },
    ],
    settings: [
      {
        key: ActivityReservationsCustomerSettingsKeys.ActivityReservations,
        source: AccessSource.Property,
      },
    ],
    permissionsConfiguration: {
      permissions: [
        {
          key: activityReservationsCustomerPermissionKeys.viewActivityReservationModule,
          source: AccessSource.Property,
        },
      ],
      permissionsConjunction: PermissionsConjunction.And,
    },
    items: [
      {
        permissionsConfiguration: {
          permissions: [
            {
              key: activityReservationsCustomerPermissionKeys.viewActivitiesDashboard,
              source: AccessSource.Property,
            },
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
        link: ACTIVITIES_RESERVATIONS_DASHBOARD_LINK,
        id: `${ACTIVITY_RESERVATIONS_MENU_ID}-dashboard`,
        translation: 'MENU.ACTIVITY_RESERVATIONS.ITEMS.DASHBOARD',
        code: 'ActivitiesDashboard',
        aliases: [
          `${acConfig.newFrontendUrls.activityReservations}/reservation`,
        ],
      },
      {
        permissionsConfiguration: {
          permissions: [
            {
              key: activityReservationsPropertyPermissionKeys.viewActivitiesPlanner,
              source: AccessSource.Property,
            },
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
        code: 'ActivitiesPlanner',
        id: `${ACTIVITY_RESERVATIONS_MENU_ID}-planner`,
        link: `${acConfig.newFrontendUrls.activityReservations}/planner`,
        translation: 'MENU.ACTIVITY_RESERVATIONS.ITEMS.PLANNER',
      },
      {
        permissionsConfiguration: {
          permissions: [
            {
              key: activityReservationsPropertyPermissionKeys.createAndEditActivityReservation,
              source: AccessSource.Property,
            },
            {
              key: activityReservationsPropertyPermissionKeys.createEditPackageReservation,
              source: AccessSource.Property,
            },
          ],
          permissionsConjunction: PermissionsConjunction.Or,
        },
        link: hasPrestep
          ? `${acConfig.newFrontendUrls.profiles}/prestep/activity-reservation`
          : `${acConfig.newFrontendUrls.activityReservations}/reservation/new`,
        translation: 'MENU.ACTIVITY_RESERVATIONS.ITEMS.CREATE_RESERVATION',
        id: `${ACTIVITY_RESERVATIONS_MENU_ID}-create-reservation`,
        aliases: [
          `${acConfig.newFrontendUrls.activityReservations}/reservation/new`,
        ],
      },
      {
        permissionsConfiguration: {
          permissions: [
            {
              key: itineraryPropertyPermissionKeys.createEditRemoveItinerary,
              source: AccessSource.Property,
            },
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
        settings: [
          {
            key: ItineraryCustomerSettingsKeys.Itinerary,
            source: AccessSource.Property,
          },
        ],
        id: `${ACTIVITY_RESERVATIONS_MENU_ID}-create-itinerary`,
        link: hasPrestep
          ? `${acConfig.newFrontendUrls.profiles}/prestep/itinerary`
          : `${acConfig.newFrontendUrls.itinerary}/activity-itinerary/new`,
        translation: 'MENU.ACTIVITY_RESERVATIONS.ITEMS.CREATE_ITINERARY',
        featureToggles: [
          {
            key: FeatureToggleName.ActivityReservationsItinerary,
            source: AccessSource.Property,
          },
        ],
      },
      {
        link: `${acConfig.newFrontendUrls.activityConfiguration}/activity-manager`,
        translation: 'MENU.ACTIVITY_RESERVATIONS.ITEMS.ACTIVITY_MANAGER',
        id: `${ACTIVITY_RESERVATIONS_MENU_ID}-activity-manager`,
      },
      {
        link: `${acConfig.newFrontendUrls.activityConfiguration}/package-manager`,
        translation: 'MENU.ACTIVITY_RESERVATIONS.ITEMS.PACKAGE_MANAGER',
        id: `${ACTIVITY_RESERVATIONS_MENU_ID}-package-manager`,
        permissionsConfiguration: {
          permissions: [
            {
              key: activityReservationsPropertyPermissionKeys.viewPackageManager,
              source: AccessSource.Property,
            },
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
        featureToggles: [
          {
            key: FeatureToggleName.ActivityPackages,
            source: AccessSource.Property,
          },
        ],
      },
      {
        link: `${acConfig.newFrontendUrls.activityConfiguration}/rate-manager`,
        translation: 'MENU.ACTIVITY_RESERVATIONS.ITEMS.RATE_MANAGER',
        id: `${ACTIVITY_RESERVATIONS_MENU_ID}-rate-manager`,
        permissionsConfiguration: {
          permissions: [
            {
              key: activityReservationsPropertyPermissionKeys.viewActivitiesRateManager,
              source: AccessSource.Property,
            },
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
      },
    ],
    breadcrumbs4thSegment: [
      [
        `${ACTIVITY_RESERVATION_LINK}/:id`,
        {
          link: ACTIVITIES_RESERVATIONS_DASHBOARD_LINK,
          title: ACTIVITIES_MANAGER_TITLE,
        },
      ],
      [
        `${ACTIVITY_RESERVATION_LINK}/new`,
        {
          link: ACTIVITIES_RESERVATIONS_DASHBOARD_LINK,
          title: ACTIVITIES_MANAGER_TITLE,
        },
      ],
      [
        `${ACTIVITY_RESERVATIONS_LINK}/:id`,
        {
          link: ACTIVITIES_RESERVATIONS_DASHBOARD_LINK,
          title: ACTIVITIES_MANAGER_TITLE,
        },
      ],
      [
        `${ACTIVITY_RESERVATIONS_LINK}/new`,
        {
          link: ACTIVITIES_RESERVATIONS_DASHBOARD_LINK,
          title: ACTIVITIES_MANAGER_TITLE,
        },
      ],
      [
        `${ACTIVITY_CONFIGURATION_LINK}/rate-manager/add-rate`,
        {
          link: ACTIVITY_CONFIGURATION_DASHBOARD_LINK,
          title: ACTIVITIES_RATE_MANAGER_TITLE,
        },
      ],
      [
        `${ACTIVITY_MANAGER_LINK}/edit-activity/:id`,
        {
          link: ACTIVITY_MANAGER_LINK,
          title: ACTIVITIES_MANAGER_DASHBOARD_TITLE,
        },
      ],
      [
        `${ACTIVITY_MANAGER_LINK}/add-activity`,
        {
          link: ACTIVITY_MANAGER_LINK,
          title: ACTIVITIES_MANAGER_DASHBOARD_TITLE,
        },
      ],
      [
        `${ACTIVITY_CONFIGURATION_LINK}/rate-manager/edit-rate/Default/:id`,
        {
          link: ACTIVITY_CONFIGURATION_DASHBOARD_LINK,
          title: ACTIVITIES_RATE_MANAGER_TITLE,
        },
      ],
      [
        `${ACTIVITY_CONFIGURATION_LINK}/rate-manager/edit-rate/Standard/:id`,
        {
          link: ACTIVITY_CONFIGURATION_DASHBOARD_LINK,
          title: ACTIVITIES_RATE_MANAGER_TITLE,
        },
      ],
      [
        `${PACKAGE_MANAGER_LINK}/new`,
        { link: PACKAGE_MANAGER_LINK, title: ACTIVITIES_PACKAGE_MANAGER_TITLE },
      ],
    ],
  };
};
