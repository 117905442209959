import { useEffect, useMemo, useState } from 'react';
import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';

import {
  AuthorizationProcessStatus,
  authorizationProcessStatusChangeEventCreator,
  AuthorizationProcessStatusChangeEventPayload,
} from '@ac/library-utils/dist/services/login';

interface UseAuthorizationProcessStatusResult {
  authorizationProcessStatus: AuthorizationProcessStatus | undefined;
}

interface UseAuthorizationProcessStatusParams {
  onChange?: (details: AuthorizationProcessStatusChangeEventPayload) => void;
}

export const useAuthorizationProcessStatus = ({
  onChange,
}: UseAuthorizationProcessStatusParams = {}): UseAuthorizationProcessStatusResult => {
  const [authorizationProcessStatus, setAuthorizationProcessStatus] = useState<
    AuthorizationProcessStatus | undefined
  >();

  const eventBus = useMemo(() => getCurrentGlobalEventBus(), []);

  useEffect(() => {
    const handler = (
      payload: AuthorizationProcessStatusChangeEventPayload
    ): void => {
      setAuthorizationProcessStatus(payload.authorizationProcessStatus);
      if (onChange) {
        onChange(payload);
      }
    };

    const removeListener = eventBus.subscribe(
      authorizationProcessStatusChangeEventCreator,
      handler
    );

    return (): void => {
      removeListener();
    };
  }, [eventBus, authorizationProcessStatus, onChange]);

  return {
    authorizationProcessStatus,
  };
};
