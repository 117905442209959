import { Context } from 'frontend-container/components/Menu/components/Context';
import { getCroContextById } from 'frontend-container/components/Menu/components/CroContext/service';
import { getProfileCenterContextById } from 'frontend-container/components/Menu/components/ProfileCentersContext/service';
import {
  getPropertyContextById,
  getPropertyContexts,
} from 'frontend-container/components/Menu/components/PropertyContext/service';
import { Workspace } from 'frontend-container/components/Menu/types';
import { getInitialContext } from 'frontend-container/components/Menu/utils/selectInitialContext';
import { getCurrentWorkspaceType } from 'frontend-container/components/Router/utils/getCurrentContextType';

import { SessionService } from '@ac/library-utils/dist/services';
import { isDefined } from '@ac/library-utils/dist/utils';

export type SelectedWorkspaceUnits = {
  selectedCro: Context | undefined;
  selectedProperty: Context | undefined;
  selectedProfileCenter: Context | undefined;
  currentUnit: Context | undefined;
};

export const getSelectedWorkspaceUnits = (): SelectedWorkspaceUnits => {
  const propertyId = SessionService.getPropertyId();
  const profileCenterId = SessionService.getProfileCenterId();
  const centralReservationOfficeId =
    SessionService.getCentralReservationOfficeId();
  const propertyContexts = getPropertyContexts();

  const propertyContext = isDefined(propertyId)
    ? getPropertyContextById(propertyId)
    : getInitialContext(propertyContexts, SessionService.getPropertyId());

  const croContext = isDefined(centralReservationOfficeId)
    ? getCroContextById(centralReservationOfficeId)
    : undefined;

  const profileCenterContext = isDefined(profileCenterId)
    ? getProfileCenterContextById(profileCenterId)
    : undefined;

  const currentUnit = {
    [Workspace.CRO]: croContext,
    [Workspace.PROFILE_CENTER]: profileCenterContext,
    [Workspace.PROPERTY]: propertyContext,
    [Workspace.CONFIGURATION]: propertyContext,
    [Workspace.NONE]: undefined,
  };

  const currentContextType = getCurrentWorkspaceType();

  return {
    selectedCro: croContext,
    selectedProperty: propertyContext,
    selectedProfileCenter: profileCenterContext,
    currentUnit: currentContextType
      ? currentUnit[currentContextType]
      : undefined,
  };
};
