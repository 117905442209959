import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { configurationMenu } from 'frontend-container/components/Menu/configuration/multiContextModules/configuration';
import { MenuElement } from 'frontend-container/components/Menu/types';
import {
  MAC_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX,
} from 'frontend-container/shared/constants';

import { customerPermissionKeys } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const identityPermissions = customerPermissionKeys.identity;

export const USERS_MENU_ID = 'menu-users';

export const usersMenu: MenuElement = {
  workspaceType: configurationMenu.workspaceType,
  translation: 'MENU.USERS.TITLE',
  icon: IconName.profile,
  id: USERS_MENU_ID,
  items: [
    {
      link: `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/users`,
      id: `${USERS_MENU_ID}-items-list`,
      aliases: [
        `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/users/new`,
        `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/users/:userId/details`,
      ],
      translation: 'MENU.USERS.ITEMS.LIST',
      permissionsConfiguration: {
        permissions: [
          { key: identityPermissions.viewUsers, source: AccessSource.Tenant },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX};`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX};`,
    },
    {
      link: `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/roles`,
      translation: 'MENU.USERS.ITEMS.ROLES',
      id: `${USERS_MENU_ID}-roles`,
      permissionsConfiguration: {
        permissions: [
          { key: identityPermissions.viewRoles, source: AccessSource.Tenant },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
  ],
};
