import { Workspace } from 'frontend-container/components/Menu/types';
import { isConfigurationModule } from 'frontend-container/components/Menu/utils/modules/configuration';
import { isFloorPlanModule } from 'frontend-container/components/Menu/utils/modules/floorPlan';
import { getSelectedMainMenuItem } from 'frontend-container/components/Router/utils/getSelectedMainMenuItem';

import { LoginService } from '@ac/library-utils/dist/services';

export const isConfigurationContextVisible = (): boolean => {
  if (LoginService.isSuperUser()) {
    return false;
  }

  const pathUtilFunctions = [isConfigurationModule, isFloorPlanModule];

  const { element } = getSelectedMainMenuItem();
  const isModuleInConfigurationContext =
    element?.workspaceType() === Workspace.CONFIGURATION;

  const isVisible =
    isModuleInConfigurationContext || pathUtilFunctions.some((func) => func());

  return isVisible;
};
