import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getCroContexts } from 'frontend-container/components/Menu/components/CroContext/service';
import {
  getCurrentPropertyIdentifierColor,
  getPropertyContexts,
  redirectToCroOrNoPermissionsPage,
} from 'frontend-container/components/Menu/components/PropertyContext/service';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/utils/isMenuV2Enabled';
import { useRouterContext } from 'frontend-container/components/Router/context/context';
import { useSelectContext } from 'frontend-container/components/Router/utils/useSelectContext';
import { redirectToProperRegionIfNecessary } from 'frontend-container/utils/region/redirectToProperRegionIfNecessary';

import { ContextComponent } from '../Context';
import { useBusinessDateTime } from '../Context/components/ContextDropdown/BusinessDate';

interface Props {
  color?: string;
  isContextSwitchHidden?: boolean;
  triggerId?: string;
}

export const PropertyContext = ({
  color,
  isContextSwitchHidden,
  triggerId,
}: Props): JSX.Element => {
  const selectedProperty = useRouterContext((store) => store.selectedProperty);
  const { t } = useTranslation();
  const handleSelect = useSelectContext();

  const isMenuV2Enabled = getIsMenuV2Enabled();

  useEffect(() => {
    if (isMenuV2Enabled) {
      return;
    }

    if (selectedProperty) {
      const redirected = redirectToProperRegionIfNecessary(selectedProperty);
      if (redirected) {
        return;
      }
    }

    if (getPropertyContexts().length === 0) {
      redirectToCroOrNoPermissionsPage(getCroContexts());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !selectedProperty ? (
    <ac-loader-covering />
  ) : (
    <ContextComponent
      selectedContext={selectedProperty}
      useDateTime={useBusinessDateTime}
      onSelect={handleSelect}
      color={color ?? getCurrentPropertyIdentifierColor()}
      dateLabel={t('MENU.CONTEXT.PROPERTY.DATE')}
      timeLabel={t('MENU.CONTEXT.PROPERTY.TIME')}
      isContextSwitchHidden={isContextSwitchHidden}
      triggerId={triggerId}
    />
  );
};
