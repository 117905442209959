import { useTranslation } from 'react-i18next';
import { GlobalSearchMenuElementItem } from 'frontend-container/components/Menu/components/GlobalSearchModal/types';

import {
  BadgeSize,
  ColorsPaletteHEX,
  HighlighterType,
  TextWeight,
} from '@ac/web-components';

interface Props {
  item: GlobalSearchMenuElementItem;
  highlightedText?: string;
}

export const GlobalSearchModalListItemText = ({
  item,
  highlightedText,
}: Props): JSX.Element | null => {
  const { t } = useTranslation();

  if (item.isMenuItem) {
    return (
      <ac-flex grow class="overflow-x-hidden">
        <ac-highlighter
          type={HighlighterType.bold}
          searchWords={highlightedText}
        >
          <ac-text class="global-search-list-item-text no-wrap">
            {item.translation}
          </ac-text>
        </ac-highlighter>
        {item.badgeLabel && (
          <ac-badge
            class="ac-margin-inline-start-sm flex-shrink"
            content={item.badgeLabel}
            color={ColorsPaletteHEX.gray200}
            size={BadgeSize.md}
          />
        )}
      </ac-flex>
    );
  }

  return (
    <>
      {highlightedText && (
        <ac-overflow-ellipsis>
          <ac-text
            weight={TextWeight.semibold}
            class="ac-margin-inline-end-xs global-search-list-item-text"
          >
            {highlightedText}
          </ac-text>
        </ac-overflow-ellipsis>
      )}
      {item.badgeLabel && (
        <ac-flex class="ac-spacing-horizontal-xs">
          <ac-badge
            content={t(item.badgeLabel)}
            color={ColorsPaletteHEX.gray200}
            size={BadgeSize.lg}
          />
        </ac-flex>
      )}
      <ac-text class="no-wrap global-search-list-item-text">
        {t(item.translation)}
      </ac-text>
    </>
  );
};
