import { compareRouteLinkWithPathname } from 'frontend-container/components/Menu/components/DocumentTitle/service';
import { MenuElementItem } from 'frontend-container/components/Menu/types';

export const getIsMenuElementItemActive = (
  menuElementItem: MenuElementItem
): boolean => {
  const isConfigurationItem = menuElementItem.link.includes('configuration');

  return compareRouteLinkWithPathname(
    {
      // prefer first alias as it's more accurate for configuration items
      link: isConfigurationItem
        ? (menuElementItem.aliases?.[0] ?? menuElementItem.link)
        : menuElementItem.link,
      routeTranslation: '',
      moduleTranslation: '',
    },
    window.location.pathname
  );
};
