import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';
import { navigateToPageEventCreator } from 'frontend-container/shared/navigation/events';
import { createRouter } from 'frontend-container/utils/router';

export const replaceWithPage = (url: string): void => {
  const router = createRouter();

  router.navigateTo(url, { replace: true });

  const eventBus = getCurrentGlobalEventBus();
  eventBus.dispatch(navigateToPageEventCreator());
};
