import {
  MenuElement,
  MenuElementItem,
} from 'frontend-container/components/Menu/types';

type MatchingFunction = (value: string) => boolean;

const getLinkSafeRegex = (link: string, length: number): RegExp | undefined => {
  const regexInjectionChars = /[.*+?^${}()|[\]\\]/;
  if (regexInjectionChars.test(link)) {
    const regExpLink = link.substr(0, length).replace(/:[^\s/]+/g, '([^/]+)');
    const safeRegExpLink = regExpLink.replace(
      new RegExp(regexInjectionChars, 'g'),
      '\\$&'
    );
    const safeRegex = new RegExp(`^${safeRegExpLink}$`);

    return safeRegex;
  }

  return undefined;
};

const findSelectedItem = (item: MenuElementItem, pathname: string): boolean => {
  const links = [item.link, ...(item.aliases ?? [])];

  return links.some((link) => {
    const index = link.indexOf('?');
    const length = index >= 0 ? index : link.length;
    const isLinkSearchMatch =
      index >= 0
        ? window.location.search &&
          link.substring(index, link.length).startsWith(window.location.search)
        : true;

    const clearedPathName = pathname.endsWith('/')
      ? pathname.substring(0, pathname.length - 1)
      : pathname;

    const linkSafeRegex = getLinkSafeRegex(clearedPathName, length);

    if (linkSafeRegex) {
      return clearedPathName.match(linkSafeRegex) && isLinkSearchMatch;
    }

    return (
      `${clearedPathName}`.match(
        new RegExp(
          `^${link.substr(0, length).replace(/:[^\s/]+/g, '([\\w-]+)')}$`
        )
      ) && isLinkSearchMatch
    );
  });
};

const getFallbackElement = (
  menuElements: MenuElement[]
): MenuElement | undefined => {
  const createIsMatchingFunction = (): MatchingFunction => {
    let prevMatchLength: number = 0;

    return (value: string): boolean => {
      if (
        window.location.pathname.startsWith(value) &&
        value.length > prevMatchLength
      ) {
        prevMatchLength = value.length;

        return true;
      }

      return false;
    };
  };

  const isMatching = createIsMatchingFunction();

  return menuElements.reduce<MenuElement | undefined>(
    (prevElement, element) => {
      const hasMatchedElement = element.items.reduce<boolean>(
        (hasMatchedItem, item) => {
          if (isMatching(item.link)) {
            return true;
          }

          return (
            !!item.aliases?.reduce<boolean>((hasMatchedAlias, alias) => {
              return isMatching(alias) || hasMatchedAlias;
            }, false) || hasMatchedItem
          );
        },
        false
      );

      return hasMatchedElement ? element : prevElement;
    },
    undefined
  );
};

export type SelectedMenuElement = {
  element?: MenuElement;
  item?: MenuElementItem;
};

export type SelectedMenuElementCache = {
  pathname: string;
} & SelectedMenuElement;

export type FindSelectedMenuElementOptions = {
  customPathname?: string;
  cache?: SelectedMenuElementCache;
};

export const findSelectedMenuElement = (
  menuElements: MenuElement[],
  options?: FindSelectedMenuElementOptions
): SelectedMenuElement => {
  const pathname = options?.customPathname ?? window.location.pathname;

  const item = [...menuElements.flatMap((menuItem) => menuItem.items)]
    .reverse()
    .find((element) => findSelectedItem(element, pathname));

  let element = menuElements.reverse().find((menuElement) => {
    return !!menuElement.items.find((elementItem) =>
      findSelectedItem(elementItem, pathname)
    );
  });

  menuElements.reverse();

  if (!element) {
    element = getFallbackElement(menuElements);
  }

  if (options?.cache) {
    options.cache.element = element;
    options.cache.item = item;
    options.cache.pathname = window.location.pathname;
  }

  return { element, item };
};
