import { Dispatch, useReducer } from 'react';

import { UserPreferencesDetails } from '@ac/library-api';

type PreferencesFormDataAction = [
  keyof UserPreferencesDetails,
  string | boolean | undefined,
];

const reducer = (
  state: UserPreferencesDetails,
  action: PreferencesFormDataAction
): UserPreferencesDetails => {
  const [key, value] = action;
  const newState = { ...state, [key]: value };

  if (key === 'defaultCentralReservationOfficeId') {
    newState.defaultCentralReservationOfficeLandingScreen = undefined;
  }

  if (key === 'defaultPropertyId') {
    newState.defaultLandingScreen = undefined;
  }

  if (key === 'defaultProfileCenterId') {
    newState.defaultProfileCenterLandingScreen = undefined;
  }

  return newState;
};

type UseFormValuesResult = [
  UserPreferencesDetails,
  Dispatch<PreferencesFormDataAction>,
];

export const useFormValues = (
  initialValues: UserPreferencesDetails
): UseFormValuesResult => {
  return useReducer(reducer, initialValues);
};
