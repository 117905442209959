import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import {
  MenuElement,
  Workspace,
} from 'frontend-container/components/Menu/types';

import {
  customerPermissionKeys,
  GeneralCustomerSettingsKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const PROFILE_CENTERS_MENU_ID = 'menu-profile-centers';

export const profileCenterMenu: MenuElement = {
  workspaceType: () => Workspace.PROFILE_CENTER,
  translation: 'MENU.PROFILE_CENTERS.TITLE',
  icon: IconName.profileDetails,
  id: PROFILE_CENTERS_MENU_ID,
  settings: [
    {
      key: GeneralCustomerSettingsKeys.ProfileCenters,
      source: AccessSource.Tenant,
    },
  ],
  items: [
    {
      link: `${acConfig.newFrontendUrls.profiles}/global-view/dashboard`,
      id: `${PROFILE_CENTERS_MENU_ID}-dashboard`,
      code: 'ProfilesDashboard',
      aliases: [
        `${acConfig.newFrontendUrls.profiles}/global-view/dashboard/all`,
        `${acConfig.newFrontendUrls.profiles}/global-view/dashboard/individual`,
        `${acConfig.newFrontendUrls.profiles}/global-view/dashboard/company`,
        `${acConfig.newFrontendUrls.profiles}/global-view/dashboard/travel-agent`,

        `${acConfig.newFrontendUrls.profiles}/global-view/profiles/individual`,
        `${acConfig.newFrontendUrls.profiles}/global-view/profiles/company`,
        `${acConfig.newFrontendUrls.profiles}/global-view/profiles/travel-agent`,

        `${acConfig.newFrontendUrls.profiles}/global-view`,
      ],
      translation: 'MENU.PROFILE_CENTERS.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: customerPermissionKeys.profiles.individual.viewDetails,
            source: AccessSource.Tenant,
          },
          {
            key: customerPermissionKeys.profiles.company.viewDetails,
            source: AccessSource.Tenant,
          },
          {
            key: customerPermissionKeys.profiles.travelAgent.viewDetails,
            source: AccessSource.Tenant,
          },
          {
            key: customerPermissionKeys.profiles.shared.dataProtectionOfficer
              .seeProfilesDashboard,
            source: AccessSource.Tenant,
          },
        ],
        permissionsConjunction: PermissionsConjunction.Or,
      },
    },
  ],
};
