import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { configurationMenu } from 'frontend-container/components/Menu/configuration/multiContextModules/configuration';
import { MenuElement } from 'frontend-container/components/Menu/types';

import {
  customerPermissionKeys,
  GeneralCustomerSettingsKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const CRO_MENU_ID = 'menu-cro-structure';

export const croStructureMenu: MenuElement = {
  workspaceType: configurationMenu.workspaceType,
  translation: 'MENU.CRO_STRUCTURE.TITLE',
  icon: IconName.cro,
  id: CRO_MENU_ID,
  settings: [
    {
      key: GeneralCustomerSettingsKeys.CentralReservationOffice,
      source: AccessSource.Tenant,
    },
  ],
  permissionsConfiguration: {
    permissions: [
      {
        key: customerPermissionKeys.configuration.centralReservationOffice.view,
        source: AccessSource.Tenant,
      },
    ],
    permissionsConjunction: PermissionsConjunction.And,
  },
  items: [
    {
      link: `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/central-reservation-office`,
      id: `${CRO_MENU_ID}-cro`,
      aliases: [
        `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/central-reservation-office/:croId`,
        `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/central-reservation-office/:croId/details`,
        `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/central-reservation-office/:croId/properties`,
        `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/central-reservation-office/:croId/settings`,
      ],
      translation: 'MENU.CRO_STRUCTURE.ITEMS.DASHBOARD',
    },
  ],
};
