import { useTranslation } from 'react-i18next';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/utils/isMenuV2Enabled';

import {
  openUserDocumentation,
  useSharedTooltipPresenter,
} from '@ac/react-infrastructure';
import {
  ButtonPattern,
  ButtonTheme,
  IconName,
  MenuButtonSize,
  Size,
  TargetValueObject,
} from '@ac/web-components';

interface Props {
  className?: string;
}

export const UserDocumentationButton = ({ className }: Props): JSX.Element => {
  const { t } = useTranslation();
  const isMenuV2Enabled = getIsMenuV2Enabled();

  const { createShowHandler } = useSharedTooltipPresenter({
    targetValue: TargetValueObject.mainMenu,
  });

  const toggleHelpModal = (): void => {
    const container = window.ACP?.container;
    if (!container?.isContextHelpOpened) {
      openUserDocumentation();
    } else {
      container?.closeContextHelpPanel?.();
    }
  };

  return isMenuV2Enabled ? (
    <>
      <ac-menu-button
        size={MenuButtonSize.trigger}
        transparent
        onClick={toggleHelpModal}
        class={className}
        id="user-documentation-button"
        onMouseEnter={createShowHandler({
          text: t('MENU.USER_DOCUMENTATION.TOOLTIP'),
        })}
      >
        <ac-icon icon={IconName.help} size={Size.lg} />
      </ac-menu-button>
    </>
  ) : (
    <ac-button
      pattern={ButtonPattern.tertiary}
      theme={!getIsMenuV2Enabled() ? ButtonTheme.light : ButtonTheme.dark}
      onClick={openUserDocumentation}
      class={className}
    >
      <ac-button-content icon={IconName.help} />
    </ac-button>
  );
};
