import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { configurationMenu } from 'frontend-container/components/Menu/configuration/multiContextModules/configuration';
import { PROFILE_CENTERS_MENU_ID } from 'frontend-container/components/Menu/configuration/profileCenterModules/profileCenters';
import { MenuElement } from 'frontend-container/components/Menu/types';

import {
  customerPermissionKeys,
  GeneralCustomerSettingsKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const configurationProfileCentersMenu: MenuElement = {
  workspaceType: configurationMenu.workspaceType,
  translation: 'MENU.PROFILE_CENTERS.CONFIGURATION_TITLE',
  icon: IconName.consent,
  id: PROFILE_CENTERS_MENU_ID,
  settings: [
    {
      key: GeneralCustomerSettingsKeys.ProfileCenters,
      source: AccessSource.Tenant,
    },
  ],
  permissionsConfiguration: {
    permissions: [
      {
        key: customerPermissionKeys.configuration.profileCenters.view,
        source: AccessSource.Tenant,
      },
    ],
    permissionsConjunction: PermissionsConjunction.And,
  },
  items: [
    {
      link: `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/profile-center-structure`,
      id: `${PROFILE_CENTERS_MENU_ID}-profile-center-structure`,
      translation: 'MENU.PROFILE_CENTERS.CONFIGURATION_TITLE',
    },
  ],
};
