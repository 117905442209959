import { getCurrentAccessSource } from 'frontend-container/components/Menu/authorization/accessConfiguration';
import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import {
  MenuElement,
  Workspace,
} from 'frontend-container/components/Menu/types';

import { customerPermissionKeys } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const PROPERTY_USERS_MENU_ID = 'menu-property-users';
const propertyUsersLink = `${acConfig.newFrontendUrls.configurationv2}/property/property-users`;

export const propertyUsers: MenuElement = {
  workspaceType: () => Workspace.PROPERTY,
  translation: 'MENU.PROPERTY_USERS.TITLE',
  icon: IconName.systemUsers,
  id: PROPERTY_USERS_MENU_ID,
  permissionsConfiguration: {
    permissions: [
      {
        key: customerPermissionKeys.identity.viewPropertyUsers,
        source: AccessSource.Property,
      },
    ],
    permissionsConjunction: PermissionsConjunction.Or,
  },
  allowedWhen: () => getCurrentAccessSource() === AccessSource.Property,
  items: [
    {
      link: propertyUsersLink,
      translation: 'MENU.PROPERTY_USERS.ITEMS.DASHBOARD',
      id: `${PROPERTY_USERS_MENU_ID}-dashboard`,
    },
  ],
};
