import { Workspace } from 'frontend-container/components/Menu/types';
import { isProfileCentersModuleLanding } from 'frontend-container/components/Menu/utils/modules/profileCenters';
import { getSelectedMainMenuItem } from 'frontend-container/components/Router/utils/getSelectedMainMenuItem';

export const isProfileCentersContextVisible = (): boolean => {
  const { element } = getSelectedMainMenuItem();
  const isModuleInProfileCenterContext =
    element?.workspaceType() === Workspace.PROFILE_CENTER;

  return isModuleInProfileCenterContext || isProfileCentersModuleLanding();
};
