import { Workspace } from 'frontend-container/components/Menu/types';
import { getCurrentWorkspaceType } from 'frontend-container/components/Router/utils/getCurrentContextType';
import { BusinessContextContainer } from 'frontend-container/publicApi';
import { getBusinessContextData } from 'frontend-container/shared/businessContext/getBusinessContext';
import { getCurrentUnitFromBusinessContext } from 'frontend-container/shared/businessContext/getCurrentUnit';
import { businessContextProvider } from 'frontend-container/shared/businessContext/provider/providerInstance';
import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';
import { definedProps } from 'frontend-container/utils/definedProps';
import { setUpWebSocketsApi } from 'frontend-container/utils/setUpWebSocketsApi';

import {
  BusinessContextUnitIdentifier,
  globalBusinessContextUpdatedEventCreator,
  SepModuleBusinessContextData,
} from '@ac/library-api';
import { SessionService } from '@ac/library-utils/dist/services';
import { WBC_CONFIGURATION } from '@ac/web-components';

export const selectUnitInBusinessContext = async (
  unit: BusinessContextUnitIdentifier
): Promise<SepModuleBusinessContextData> => {
  const contextContainer = window.ACP?.businessContext;
  if (!contextContainer) {
    throw new Error(
      'The context container is not defined. Please use setInitialBusinessContextForUnit() firstly.'
    );
  }

  const isNewMenu =
    contextContainer.context.user.preferences?.preferences?.displayNewMenu;
  const targetUnit = isNewMenu
    ? { ...getCurrentUnitFromBusinessContext(), ...definedProps(unit) }
    : unit;

  const currentBusinessContextData = getBusinessContextData();
  const newBusinessContextData =
    await businessContextProvider.getBusinessContext(targetUnit);

  if (currentBusinessContextData !== newBusinessContextData) {
    saveNewBusinessContext(
      targetUnit,
      contextContainer,
      newBusinessContextData
    );

    setUpWebSocketsApi();
  }

  return newBusinessContextData;
};

const saveNewBusinessContext = async (
  unit: BusinessContextUnitIdentifier | undefined,
  contextContainer: BusinessContextContainer,
  context: SepModuleBusinessContextData
): Promise<void> => {
  const workspaceType = getCurrentWorkspaceType();

  if (unit?.propertyId) {
    SessionService.setPropertyId(unit.propertyId);
  }
  if (unit?.centralReservationOfficeId) {
    SessionService.setCentralReservationOfficeId(
      unit.centralReservationOfficeId
    );
  }
  if (unit?.profileCenterId) {
    SessionService.setProfileCenterId(unit.profileCenterId);
  }

  contextContainer.context = context;

  if (
    workspaceType === Workspace.CRO &&
    context.centralReservationOffice?.dateConfiguration
  ) {
    WBC_CONFIGURATION.set({
      dateConfiguration: context.centralReservationOffice.dateConfiguration,
    });
  } else if (
    workspaceType === Workspace.PROPERTY &&
    context.property?.dateConfiguration
  ) {
    WBC_CONFIGURATION.set({
      dateConfiguration: context.property.dateConfiguration,
    });
  } else if (context.customer?.dateConfiguration) {
    WBC_CONFIGURATION.set({
      dateConfiguration: context.customer.dateConfiguration,
    });
  }

  getCurrentGlobalEventBus().dispatch(
    globalBusinessContextUpdatedEventCreator(context)
  );
};
