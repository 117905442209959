import { FC } from 'react';
import { SubItem } from 'frontend-container/components/Menu/components/Item/SubItem/SubItem';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import { useTranslationWithEnglishInParentheses } from 'frontend-container/utils/hooks/useTranslationWithEnglishParentheses';
import { isError403IpWhitlelistPage } from 'frontend-container/utils/setupWhitelistingCatcher';

import { AccountLoginProcedureType } from '@ac/library-api';
import { LoginService } from '@ac/library-utils/dist/services';

interface Props {
  togglePasswordModal: () => void;
  toggleUserPreferencesModal: () => void;
}

export const UserInfotipBody: FC<Props> = ({
  togglePasswordModal,
  toggleUserPreferencesModal,
}) => {
  const { accountLoginProcedureType } = userService.getCurrentUserDetails();
  const shouldShowChangePasswordOption =
    accountLoginProcedureType !==
    AccountLoginProcedureType.FederatedAccountLogin;

  const translateWithEnglishParentheses =
    useTranslationWithEnglishInParentheses();

  const logout = async (): Promise<void> => {
    await userService.logout(false);
  };

  return (
    <>
      {!isError403IpWhitlelistPage() && (
        <>
          {shouldShowChangePasswordOption && (
            <SubItem
              key={'password-menu'}
              active={false}
              item={{
                id: 'password-menu',
                link: '',
                translation: 'MENU.LOGGED_USER.CHANGE_PASSWORD',
              }}
              onClick={togglePasswordModal}
            />
          )}
          {!LoginService.isSuperUser() && (
            <SubItem
              key={'language-menu'}
              active={false}
              item={{
                id: 'language-menu',
                link: '',
                translation: translateWithEnglishParentheses(
                  'MENU.LOGGED_USER.USER_PREFERENCES.TITLE'
                ),
              }}
              onClick={toggleUserPreferencesModal}
            />
          )}
        </>
      )}
      <SubItem
        key={'logout'}
        active={false}
        item={{
          id: 'logout',
          link: '',
          translation: 'MENU.LOGGED_USER.LOGOUT',
        }}
        onClick={logout}
      />
    </>
  );
};
