import { ReloadCurrentBusinessContextDataParameters } from 'frontend-container/publicApi/windowObject';
import { businessContextProvider } from 'frontend-container/shared/businessContext/provider/providerInstance';
import { reloadCurrentBusinessContextData } from 'frontend-container/shared/businessContext/provider/reloadCurrentBusinessContextData';
import { selectUnitInBusinessContext } from 'frontend-container/shared/businessContext/selectUnitInBusinessContext';

import {
  BusinessContextUnitIdentifier,
  SepModuleBusinessContextData,
} from '@ac/library-api';
import { LoginService } from '@ac/library-utils/dist/services';

export const setInitialBusinessContextForUnit =
  async (): Promise<SepModuleBusinessContextData> => {
    const tenantId = LoginService.authData()?.tenantId;
    const initialUnit: BusinessContextUnitIdentifier = { tenantId };

    const businessContextData =
      await businessContextProvider.getBusinessContext(initialUnit);

    if (window.ACP) {
      window.ACP.businessContext = {
        context: businessContextData,
        selectUnit: selectUnitInBusinessContext,
        loadBusinessContextData: (
          unit
        ): Promise<SepModuleBusinessContextData> =>
          businessContextProvider.getBusinessContext(unit),
        clearCache: (): void => businessContextProvider.clearCache(),
        reloadCurrentBusinessContextData: (
          parameters?: ReloadCurrentBusinessContextDataParameters
        ): Promise<void> => reloadCurrentBusinessContextData(parameters),
      };
    }

    return businessContextData;
  };
