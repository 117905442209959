import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getFullMenu } from 'frontend-container/components/Menu/configuration';

import { isDefined } from '@ac/library-utils/dist/utils';

type OptionalSegment = {
  link: string;
  title: string;
};

type OptionalSegmentResult = OptionalSegment | null;

const urlSearchSegmentMap = new Map([
  ['meetingsAndEventsEvent', 'Event'],
  ['meetingsAndEventsBooking', 'Booking'],
]);

const hashLength = 36;

const generateSelector = (pathName: string, search: string): string => {
  const pathSegments = pathName.slice(1).split('/');

  let url = pathSegments.reduce((acc, segment) => {
    if (segment.length === hashLength) {
      return `${acc}/:id`;
    } else {
      return segment.length ? `${acc}/${segment}` : acc;
    }
  }, '');

  if (search) {
    urlSearchSegmentMap.forEach((value, key) => {
      if (search.includes(key)) {
        url += `?${value}`;
      }
    });
  }

  return url;
};

export const useBreadCrumbsOptionalSegmentDictionary = (
  pathName: string,
  search: string
): OptionalSegmentResult => {
  const { t } = useTranslation();

  const modulesBreadcrumbs4thSegment = useMemo(
    () =>
      getFullMenu()
        .map((menuElement) => menuElement.breadcrumbs4thSegment)
        .filter(isDefined)
        .flat(),
    []
  );

  const breadcrumbsOptionalSegmentMap = useMemo(
    () =>
      new Map([...modulesBreadcrumbs4thSegment] as
        | Iterable<[string, OptionalSegment]>
        | undefined),
    [modulesBreadcrumbs4thSegment]
  );

  const hasOptionalSegment = useCallback(
    (selector: string): boolean => breadcrumbsOptionalSegmentMap.has(selector),
    [breadcrumbsOptionalSegmentMap]
  );

  const getBreadcrumbSegment = useCallback(
    (selector: string): OptionalSegment =>
      breadcrumbsOptionalSegmentMap.get(selector) as OptionalSegment,
    [breadcrumbsOptionalSegmentMap]
  );

  return useMemo(() => {
    let optionalSegment = null;
    const selector = generateSelector(pathName, search);

    if (hasOptionalSegment(selector)) {
      const currentSegmentData = getBreadcrumbSegment(selector);

      optionalSegment = {
        title: t(currentSegmentData.title),
        link: currentSegmentData.link,
      };
    }

    return optionalSegment;
  }, [t, getBreadcrumbSegment, hasOptionalSegment, pathName, search]);
};
