import { FC } from 'react';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/utils/isMenuV2Enabled';

import { getInitials } from '@ac/library-api';
import { LoginService } from '@ac/library-utils/dist/services';
import { MenuButtonSize, SystemUserTheme } from '@ac/web-components';

import './UserTrigger.scss';

interface Props {
  firstName?: string;
  lastName?: string;
}

export const UserTrigger: FC<Props> = ({ firstName, lastName }: Props) => {
  const initials = getInitials(
    firstName ?? LoginService.authData()?.userType ?? '',
    lastName ?? LoginService.authData()?.userType ?? ''
  );

  return !getIsMenuV2Enabled() ? (
    <ac-system-user theme={SystemUserTheme.light} readonly={false}>
      {initials}
    </ac-system-user>
  ) : (
    <ac-menu-button size={MenuButtonSize.trigger}>
      <span>{initials}</span>
    </ac-menu-button>
  );
};
