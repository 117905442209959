import { Fragment, useState } from 'react';
import { BreadcrumbsButtonContextContent } from 'frontend-container/components/Menu/components/Breadcrumbs/components/BreadcrumbsButtonContextContent';
import { BreadcrumbsButtonModuleContent } from 'frontend-container/components/Menu/components/Breadcrumbs/components/BreadcrumbsButtonModuleContent';
import { isConfigurationContextVisible } from 'frontend-container/components/Menu/components/ConfigurationContext/isConfigurationContextVisible';
import { handleMenuItemRedirect } from 'frontend-container/components/Menu/components/Item/handleMenuItemRedirect';
import { handleUnsavedChangesRedirect } from 'frontend-container/components/Menu/components/Item/handleUnsavedChangesRedirect';
import { SubItem } from 'frontend-container/components/Menu/components/Item/SubItem/SubItem';
import { isPropertyContextVisible } from 'frontend-container/components/Menu/components/PropertyContext';
import { SafeFloatingWrapper } from 'frontend-container/components/Menu/components/SafeFloatingWrapper/SafeFloatingWrapper';
import {
  MenuElement,
  MenuElementItem,
} from 'frontend-container/components/Menu/types';
import { useFavoriteMenuItems } from 'frontend-container/components/Menu/utils/useFavoriteMenuItems';

import { isDefined } from '@ac/library-utils/dist/utils';
import { FlexGap, IconName, MenuButtonSize, Size } from '@ac/web-components';

import { FloatingTree } from '@floating-ui/react';

import './Breadcrumbs.scss';

export enum BreadcrumbsButtonType {
  context = 'context',
  module = 'module',
  items = 'items',
}

interface Props {
  id: string;
  currentModule?: MenuElement;
  content?: string;
  showIcon?: boolean;
  iconSize?: Size.xs | Size.sm;
  onMouseEnter?: ((event: MouseEvent) => void) | undefined;
  onMouseLeave?: ((event: MouseEvent) => void) | undefined;
  type?: BreadcrumbsButtonType;
  subItems?: MenuElementItem[];
  menuItems?: MenuElement[];
  isMenuHeader?: boolean;
  transparent?: boolean;
  optionalLink?: string;
}

export const BreadcrumbsButton = ({
  content,
  id,
  currentModule,
  onMouseEnter,
  onMouseLeave,
  showIcon = true,
  iconSize = Size.xs,
  type,
  subItems,
  menuItems,
  isMenuHeader,
  transparent,
  optionalLink,
}: Props): JSX.Element | null => {
  const [activeItem, setActiveItem] = useState<string>();
  const favorites = useFavoriteMenuItems();

  const resetSelection = (): void => {
    setActiveItem(undefined);
  };

  const handleOnMouseOver = (event: MouseEvent): void => {
    onMouseEnter?.(event);
  };

  const handleOnMouseLeave = (event: MouseEvent): void => {
    onMouseLeave?.(event);
    resetSelection();
  };

  const handleOnSubItemClick = async (
    link: string,
    menuElementItems?: MenuElementItem[],
    onBeforeRedirectCallback?: () => void
  ): Promise<void> => {
    return handleUnsavedChangesRedirect(
      false,
      link,
      async () =>
        await handleMenuItemRedirect(
          link,
          menuElementItems,
          onBeforeRedirectCallback,
          true
        )
    );
  };

  const handleOptionalSegmentClick = (): void => {
    if (optionalLink) {
      history.pushState({}, '', optionalLink);
    }
  };

  if (!isDefined(content)) {
    return null;
  }

  if (
    type === BreadcrumbsButtonType.context &&
    isConfigurationContextVisible()
  ) {
    return null;
  }

  const displayedMenuItems = isPropertyContextVisible()
    ? (menuItems?.filter((item) => item.id !== currentModule?.id) ?? [])
    : menuItems;

  const renderBreadcrumbsButtonContent = (
    element: MenuElement
  ): JSX.Element => {
    return (
      <BreadcrumbsButtonModuleContent
        key={element.id}
        element={element}
        handleOnSubItemClick={handleOnSubItemClick}
        onSubItemMouseOver={(): void => setActiveItem(element.id)}
      />
    );
  };

  return (
    <Fragment>
      {showIcon && (
        <ac-icon
          icon={IconName.actionRight}
          size={iconSize}
          class="breadcrumbs-divider"
        />
      )}
      {!type && optionalLink ? (
        <ac-menu-button
          id={id}
          onMouseEnter={handleOnMouseOver}
          onMouseLeave={handleOnMouseLeave}
          transparent={transparent}
          onClickCallback={handleOptionalSegmentClick}
          data-test-selector={id}
        >
          <span>{content}</span>
        </ac-menu-button>
      ) : (
        <FloatingTree>
          <SafeFloatingWrapper
            isDelayRestMode={isMenuHeader}
            floatingContent={
              <div
                className="menu-subitems-wrapper layout-direction menu-subitems-container menu-subitems-container-dynamic"
                data-test-selector="new-menu-subitems-wrapper"
              >
                <div className="breadcrumbs-sub-list-wrapper">
                  {type === BreadcrumbsButtonType.context && (
                    <BreadcrumbsButtonContextContent />
                  )}
                  {type === BreadcrumbsButtonType.items &&
                    (subItems?.length ?? 0) > 1 &&
                    subItems?.map((element) => (
                      <SubItem
                        item={element}
                        showActionArrow={false}
                        onMouseOver={(): void => setActiveItem(element.link)}
                        spacing={FlexGap.sm}
                        key={element.link}
                        active={activeItem === element.link}
                        onClick={handleOnSubItemClick}
                      />
                    ))}
                  {type === BreadcrumbsButtonType.module && (
                    <>
                      {currentModule && isPropertyContextVisible() && (
                        <>
                          {renderBreadcrumbsButtonContent(currentModule)}
                          <div className="current-module-separator" />
                        </>
                      )}
                      {favorites && renderBreadcrumbsButtonContent(favorites)}
                      {displayedMenuItems?.map((element) => {
                        return renderBreadcrumbsButtonContent(element);
                      })}
                    </>
                  )}
                </div>
              </div>
            }
          >
            <div>
              <ac-menu-button
                size={isMenuHeader ? MenuButtonSize.lg : MenuButtonSize.md}
                id={id}
                onMouseEnter={handleOnMouseOver}
                onMouseLeave={handleOnMouseLeave}
                transparent
                data-test-selector={id}
              >
                <span>{content}</span>
              </ac-menu-button>
            </div>
          </SafeFloatingWrapper>
        </FloatingTree>
      )}
    </Fragment>
  );
};
