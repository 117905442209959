import { WorkspaceItem } from 'frontend-container/components/Menu/hooks/useWorkspaceMenuItems';
import i18n from 'frontend-container/i18n';
import { getCustomerContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

import { customerPermissionKeys } from '@ac/library-api';
import { LoginService } from '@ac/library-utils/dist/services';
import { isDefined } from '@ac/library-utils/dist/utils';

export const isConfigurationEnabled = (): boolean => {
  if (LoginService.isSuperUser()) {
    return false;
  }

  const customerContext = getCustomerContextData();
  const permissions = customerContext?.permissions.permissionIds;

  if (!permissions) {
    return false;
  }

  const configurationPermission = permissions.find(
    (item) =>
      item === customerPermissionKeys.configuration.accessToConfigurationModule
  );

  return isDefined(configurationPermission);
};

export const getConfigurationWorkspaceItems = (
  currentButtonOption: WorkspaceItem | undefined
): WorkspaceItem | undefined => {
  if (!currentButtonOption) return;

  const translatedSubMenuElements = currentButtonOption.subMenuElements?.map(
    (subMenu) => {
      return {
        ...subMenu,
        translation: i18n.t(subMenu.translation),
      };
    }
  );
  currentButtonOption.subMenuElements = translatedSubMenuElements;

  return currentButtonOption;
};
