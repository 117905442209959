import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/utils/isMenuV2Enabled';

import {
  AlignItems,
  FlexDirection,
  IconName,
  TextColor,
  TextSize,
  TextWeight,
} from '@ac/web-components';

interface Props {
  cashierName?: string;
}

export const CashierInfotipHeader: FC<Props> = ({ cashierName }) => {
  const { t } = useTranslation();
  const isMenuV2Enabled = getIsMenuV2Enabled();

  return isMenuV2Enabled ? (
    <ac-flex direction={FlexDirection.column} class="ac-gap-xs">
      <ac-text size={TextSize.sm} color={TextColor.darkGray}>
        {t('MENU.CASHIER.TITLE')}
      </ac-text>
      <ac-text>{cashierName ?? t('MENU.CASHIER.NOT_OPENED')}</ac-text>
    </ac-flex>
  ) : (
    <ac-flex alignItems={AlignItems.center} class="ac-gap-sm">
      <ac-icon icon={IconName.cashier} />
      <ac-flex direction={FlexDirection.column}>
        {cashierName ? (
          <ac-text weight={TextWeight.semibold}>{cashierName}</ac-text>
        ) : (
          t('MENU.CASHIER.NOT_OPENED')
        )}
        <ac-text size={TextSize.sm}>{t('MENU.CASHIER.TITLE')}</ac-text>
      </ac-flex>
    </ac-flex>
  );
};
