import { propertyLandingPagePathname } from 'frontend-container/components/LandingPage';
import { landingPageDestination } from 'frontend-container/components/LandingPage/location';
import { reservationsMenu } from 'frontend-container/components/Menu/configuration/propertyModules/reservations/reservations';
import { isIndividualReservationsModule } from 'frontend-container/components/Menu/utils/modules/individualReservations';
import { isReservationsModule } from 'frontend-container/components/Menu/utils/modules/reservations';

/**
 * regexp to match all paths with existing reservation id in url
 * e.g.
 * - /reservations/reservations/:id
 * - /reservations/reservations/:id/change-profile
 * - /reservations/reservations/:id/check-in
 * - /individual-reservations/reservations/:id
 * - /individual-reservations/reservations/:id/check-in
 */
const EXISTING_RESERVATION_ROUTE_REGEXP =
  /^\/(reservations|individual-reservations)\/reservations\/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/;

/**
 * get Reservations Dashboard location if current location includes existing
 * reservation id (reservation id will be invalid after Property Context change)
 */
const getNewUrlForReservationsModule = (
  currentLocation: Location,
  origin?: string,
  useLandingPage?: boolean
): string | undefined => {
  const { pathname, origin: currentLocationOrigin } = currentLocation;
  if (!pathname.match(EXISTING_RESERVATION_ROUTE_REGEXP)) {
    return;
  }

  const newOrigin = origin ?? currentLocationOrigin;
  const reservationsDashboardPath =
    reservationsMenu.items.find(({ code }) => code === 'ReservationDashboard')
      ?.link ?? '';

  if (useLandingPage) {
    return `${newOrigin}${propertyLandingPagePathname}?${landingPageDestination}=${reservationsDashboardPath}`;
  }

  return `${newOrigin}${reservationsDashboardPath}`;
};

export const getNewUrlForReservationsModuleIfNeeded = (
  location: Location,
  origin?: string,
  useLandingPage?: boolean
): string | undefined => {
  if (isReservationsModule() || isIndividualReservationsModule()) {
    return getNewUrlForReservationsModule(location, origin, useLandingPage);
  }

  return;
};
