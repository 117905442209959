import {
  croLandingPagePathname,
  propertyLandingPagePathname,
} from 'frontend-container/components/LandingPage/location';
import { AccessSource } from 'frontend-container/components/Menu/authorization/types';
import {
  MenuElement,
  Workspace,
} from 'frontend-container/components/Menu/types';
import { hasAnyCroContextGuard } from 'frontend-container/components/Menu/utils/modules/centralReservationOffice';

import { GeneralCustomerSettingsKeys } from '@ac/library-api';
import { IconName } from '@ac/web-components';

const APP_MENU_ID = 'menu-main-application';

export const applicationDashboardsMenu: MenuElement = {
  workspaceType: () => Workspace.CONFIGURATION,
  translation: 'MENU.APPLICATION.TITLE',
  icon: IconName.acProject,
  id: APP_MENU_ID,
  items: [
    {
      link: propertyLandingPagePathname,
      translation: 'MENU.APPLICATION.ITEMS.PROPERTY_DASHBOARD',
      id: `${APP_MENU_ID}-dashboard`,
    },
    {
      link: croLandingPagePathname,
      translation: 'MENU.APPLICATION.ITEMS.CRO_DASHBOARD',
      id: `${APP_MENU_ID}-cro-dashboard`,
      settings: [
        {
          key: GeneralCustomerSettingsKeys.CentralReservationOffice,
          source: AccessSource.CentralReservationOffice,
        },
      ],
      allowedWhen: (params) => hasAnyCroContextGuard(params),
    },
  ],
};
