import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import {
  MenuElement,
  Workspace,
} from 'frontend-container/components/Menu/types';
import {
  MAC_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX,
} from 'frontend-container/shared/constants';

import {
  GeneralCustomerSettingsKeys,
  propertyPermissionKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const AR_MENU_ID = 'menu-ar-accounts';

const accountReceivablePermissions =
  propertyPermissionKeys.cashiering.accountReceivable;

export const accountsReceivableMenu: MenuElement = {
  workspaceType: () => Workspace.PROPERTY,
  translation: 'MENU.ACCOUNTS_RECEIVABLE.TITLE',
  icon: IconName.accountsReceivable,
  id: AR_MENU_ID,
  settings: [
    {
      key: GeneralCustomerSettingsKeys.AccountsReceivable,
      source: AccessSource.Property,
    },
  ],
  items: [
    {
      link: `${acConfig.newFrontendUrls.accountsReceivable}/dashboard`,
      code: 'AccountReceivableDashboard',
      id: `${AR_MENU_ID}-dashboard`,
      aliases: [
        `${acConfig.newFrontendUrls.accountsReceivable}`,
        `${acConfig.newFrontendUrls.accountsReceivable}/selected-transactions`,
        `${acConfig.newFrontendUrls.accountsReceivable}/:id/selected-transactions`,
        `${acConfig.newFrontendUrls.accountsReceivable}/selected-accounts`,
      ],
      translation: 'MENU.ACCOUNTS_RECEIVABLE.ITEMS.AR_DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: accountReceivablePermissions.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}A`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}A`,
      keyCode: 65,
    },
    {
      link: `${acConfig.newFrontendUrls.accountsReceivable}/transactions`,
      translation: 'MENU.ACCOUNTS_RECEIVABLE.ITEMS.TR_DASHBOARD',
      id: `${AR_MENU_ID}-transactions-dashboard`,
      permissionsConfiguration: {
        permissions: [
          {
            key: accountReceivablePermissions.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}=`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}=`,
      keyCode: 187,
    },
    {
      link: `${acConfig.newFrontendUrls.accountsReceivable}/create`,
      translation: 'MENU.ACCOUNTS_RECEIVABLE.ITEMS.NEW',
      id: `${AR_MENU_ID}-create`,
      permissionsConfiguration: {
        permissions: [
          {
            key: accountReceivablePermissions.manage,
            source: AccessSource.Property,
          },
          {
            key: accountReceivablePermissions.manageCreditCardARAccount,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.Or,
      },
    },
  ],
  breadcrumbs4thSegment: [
    [
      '/accounts-receivable/:id/transactions',
      {
        link: '/accounts-receivable/dashboard',
        title:
          'MENU.ACCOUNTS_RECEIVABLE.BREADCRUMB.4SEGMENT.AR_ACCOUNT_DASHBOARD',
      },
    ],
  ],
};
