import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllowedMenuItems } from 'frontend-container/components/Menu/authorization/getAllowedMenuItems';
import { Breadcrumbs } from 'frontend-container/components/Menu/components/Breadcrumbs/Breadcrumbs';
import {
  BreadcrumbsButton,
  BreadcrumbsButtonType,
} from 'frontend-container/components/Menu/components/Breadcrumbs/BreadcrumbsButton';
import { Cashier } from 'frontend-container/components/Menu/components/Cashier/Cashier';
import { isCashierVisible } from 'frontend-container/components/Menu/components/Cashier/visibility';
import { ContextManager } from 'frontend-container/components/Menu/components/Context/ContextManager';
import { defaultDocumentTitle } from 'frontend-container/components/Menu/components/DocumentTitle/service';
import { GlobalSearchMenuIcon } from 'frontend-container/components/Menu/components/GlobalSearchMenuIcon/GlobalSearchMenuIcon';
import { useGlobalSearchModalPresenterContext } from 'frontend-container/components/Menu/components/GlobalSearchModal/presenter/context';
import { handleMenuItemRedirect } from 'frontend-container/components/Menu/components/Item/handleMenuItemRedirect';
import { useMenuShortcuts } from 'frontend-container/components/Menu/components/Item/useMenuShortcuts';
import { useBrandingLogo } from 'frontend-container/components/Menu/components/Logo/useBrandingLogo';
import { useMegaMenuPresenterContext } from 'frontend-container/components/Menu/components/MegaMenu/presenter/context';
import {
  isBellVisible,
  NotificationBellContainer,
} from 'frontend-container/components/Menu/components/NotificationBell';
import { SelectedProperty } from 'frontend-container/components/Menu/components/SelectedProperty/SelectedProperty';
import { TrainingBanner } from 'frontend-container/components/Menu/components/TrainingBanner/TrainingBanner';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import { User } from 'frontend-container/components/Menu/components/User/User';
import { UserDocumentationButton } from 'frontend-container/components/Menu/components/UserDocumentation/UserDocumentation';
import { useIsWorkstationVisible } from 'frontend-container/components/Menu/components/Workstation/visibility';
import { WorkstationMenu } from 'frontend-container/components/Menu/components/Workstation/Workstation';
import { getMainApplicationMenu } from 'frontend-container/components/Menu/configuration';
import { useWorkspaceMenuItems } from 'frontend-container/components/Menu/hooks/useWorkspaceMenuItems';
import { THEME_DARK_KEY } from 'frontend-container/components/Menu/service';
import { useOverrideMenuHeight } from 'frontend-container/components/Menu/useOverrideMenuHeight';
import { useTrainingFeature } from 'frontend-container/components/Menu/useTrainingFeature';
import { isCroCreateOrEditReservationPathname } from 'frontend-container/components/Menu/utils/modules/centralReservationOffice';
import { useMaintenancePanelPresenterContext } from 'frontend-container/components/panels/maintenancePanel/presenter/context';
import { useRouterContext } from 'frontend-container/components/Router/context/context';
import { getSelectedMainMenuItem } from 'frontend-container/components/Router/utils/getSelectedMainMenuItem';
import { LocalStorage } from 'frontend-container/shared/storage/localStorage';
import { isMac } from 'frontend-container/utils/isMac';

import { LoginService } from '@ac/library-utils/dist/services';
import {
  useBreadcrumbsTitle,
  useRoutingContext,
  useSharedTooltipPresenter,
} from '@ac/react-infrastructure';
import {
  AlignItems,
  FlexDirection,
  IconName,
  JustifyContent,
  KeyboardKey,
  MenuBarSlot,
  MenuBarTheme,
  MenuButtonSize,
  TargetValueObject,
} from '@ac/web-components';

import './MenuV2.scss';

const handleShortcut = async (link: string): Promise<void> => {
  const menuElement = getMainApplicationMenu().find((element) =>
    element.items.find((item) => item.link === link)
  );

  await handleMenuItemRedirect(link, menuElement?.items);
};

const disableBackThreshold = 2;

export const MenuV2 = (): JSX.Element => {
  const { t } = useTranslation();
  const [pageName, setPageName] = useState(defaultDocumentTitle);
  const globalSearchModalPresenter = useGlobalSearchModalPresenterContext();
  const megaMenuPresenter = useMegaMenuPresenterContext();

  const allAccessConfiguration = useRouterContext(
    (store) => store.allAccessConfiguration
  );
  const menuItems = useRouterContext((store) => store.userMenuElements);
  const { visibleWorkspaceMenuItems, currentWorkspaceItem } =
    useWorkspaceMenuItems();
  const isNonProduction = useTrainingFeature();

  const mainMenuLogo = useBrandingLogo();
  const breadcrumbsTitle = useBreadcrumbsTitle();
  const { isWorkstationVisible } = useIsWorkstationVisible();
  const maintenancePanel = useMaintenancePanelPresenterContext();
  const {
    state: {
      routeChangeCallbacks: { onNavigateBack },
      onBeforePageUnload,
    },
  } = useRoutingContext();

  const { element } = getSelectedMainMenuItem();
  const [allowedElement] = element
    ? getAllowedMenuItems([element], allAccessConfiguration)
    : [];
  const isDarkMode = Boolean(LocalStorage.getParsed(THEME_DARK_KEY));

  useOverrideMenuHeight(isNonProduction, true);

  const toggleGlobalSearchModal = useCallback(
    (event: KeyboardEvent): void => {
      const isInputEvent =
        event.target &&
        !(event.target as HTMLElement).classList.contains('body');

      const isCommandOrCtrlDown =
        (isMac && event.metaKey) || (!isMac && event.ctrlKey);

      if (isCommandOrCtrlDown && event.key === '/') {
        event.preventDefault();
        event.stopPropagation();
        if (globalSearchModalPresenter.state.isVisible) {
          return globalSearchModalPresenter.cancel();
        }

        globalSearchModalPresenter.show();
      }

      // block event when some element is focused and global search is closed
      if (isInputEvent && !globalSearchModalPresenter.state.isVisible) {
        return;
      }

      if (isCommandOrCtrlDown && event.key === 'k') {
        event.preventDefault();
        event.stopPropagation();
        if (globalSearchModalPresenter.state.isVisible) {
          return globalSearchModalPresenter.cancel();
        }

        globalSearchModalPresenter.show();
      }

      if (event.key === KeyboardKey.Escape) {
        globalSearchModalPresenter.cancel();
      }
    },
    [globalSearchModalPresenter]
  );

  const openMaintenancePanel = useCallback(async (): Promise<void> => {
    if (!maintenancePanel.state.isVisible) {
      await maintenancePanel.show();
    }
  }, [maintenancePanel]);

  const toggleMegaMenu = (): void => {
    if (megaMenuPresenter.state.isVisible) {
      megaMenuPresenter.cancel();

      return;
    }

    megaMenuPresenter.show();
  };

  const isOverBackThreshold = window?.history?.length <= disableBackThreshold;

  const handleBackButton = (event: MouseEvent): void => {
    event.stopPropagation();

    if (onBeforePageUnload) {
      onBeforePageUnload();
    }

    if (!isOverBackThreshold) {
      onNavigateBack();
    }
  };

  useMenuShortcuts(false, handleShortcut);

  const { createShowHandler } = useSharedTooltipPresenter({
    targetValue: TargetValueObject.mainMenu,
  });

  useEffect(() => {
    if (breadcrumbsTitle) {
      setPageName(breadcrumbsTitle);
    }
  }, [breadcrumbsTitle]);

  useEffect(() => {
    window.addEventListener('keydown', toggleGlobalSearchModal);

    return (): void => {
      window.removeEventListener('keydown', toggleGlobalSearchModal);
    };
  }, [toggleGlobalSearchModal]);

  return (
    <ac-flex direction={FlexDirection.column}>
      <ac-flex class="layout-direction">
        <>
          <ac-menu-bar
            id="menu-v2-bar"
            onLogoDoubleClickCallback={openMaintenancePanel}
            customLogoSrc={mainMenuLogo}
            theme={isDarkMode ? MenuBarTheme.dark : MenuBarTheme.light}
            onHamburgerClickCallback={toggleMegaMenu}
            isOpen={megaMenuPresenter.state.isVisible}
          >
            <ac-menu-button
              slot={MenuBarSlot.backButton}
              onClick={handleBackButton}
              size={MenuButtonSize.sm}
              transparent
              disableHover={isOverBackThreshold}
              id="menu-v2-bar-back-button"
              onMouseEnter={createShowHandler({
                text:
                  window?.history?.length > disableBackThreshold
                    ? t('MENU.MAIN_MENU.BACK_BUTTON')
                    : t('MENU.MAIN_MENU.BACK_BUTTON_DISABLED'),
              })}
            >
              <ac-button-content
                dynamicClass={
                  isOverBackThreshold ? 'menu-v2-bar-back-disabled' : undefined
                }
                icon={IconName.arrowLeft}
              />
            </ac-menu-button>
            <ac-flex
              slot={MenuBarSlot.pageName}
              justifyContent={JustifyContent.flexStart}
              alignItems={AlignItems.center}
            >
              <BreadcrumbsButton
                id="menu-page-name"
                type={BreadcrumbsButtonType.items}
                menuItems={menuItems}
                subItems={element?.items}
                content={pageName || defaultDocumentTitle}
                showIcon={false}
                isMenuHeader
              />
            </ac-flex>
            <ac-flex alignItems={AlignItems.center} slot={MenuBarSlot.content}>
              {isCroCreateOrEditReservationPathname() && <SelectedProperty />}

              {!LoginService.isSuperUser() && <ContextManager />}

              <GlobalSearchMenuIcon
                onClickCallback={(): void =>
                  void globalSearchModalPresenter.show()
                }
              />

              {isCashierVisible() && <Cashier />}
              {isWorkstationVisible && <WorkstationMenu />}
              {isBellVisible() && <NotificationBellContainer />}
              <UserDocumentationButton />
              {LoginService.isLoggedIn() &&
                userService.getCurrentUserDetails() && <User />}
            </ac-flex>
            <ac-flex
              slot={MenuBarSlot.breadcrumbs}
              justifyContent={JustifyContent.flexStart}
              alignItems={AlignItems.center}
            >
              <Breadcrumbs
                currentModule={allowedElement}
                buttonSelectOptions={visibleWorkspaceMenuItems}
                currentButtonOption={currentWorkspaceItem}
                menuItems={menuItems}
                allAccessConfiguration={allAccessConfiguration}
              />
            </ac-flex>
          </ac-menu-bar>
          <ac-tooltip
            for="#menu-v2-bar-hamburger"
            text={t('MENU.MAIN_MENU.TOOLTIP')}
            targetValue={TargetValueObject.mainMenu}
          />
        </>
      </ac-flex>
      {isNonProduction && (
        <div className="container-banner">
          <TrainingBanner />
        </div>
      )}
    </ac-flex>
  );
};
