import { logRedirectToPreviousDomain } from 'frontend-container/components/AlternateDomainSwitching/logging/alternateDomainSwitchingLogger';
import { saveSpeedTestMarkerInStore } from 'frontend-container/components/AlternateDomainSwitching/verification/testRepetition/saveSpeedTestMarkerInStore';
import { t } from 'frontend-container/i18n';

import { ToastSeverity } from '@ac/web-components';

export const executePostRedirectAction = (): void => {
  saveSpeedTestMarkerInStore();

  displayNotificationAboutRedirect({
    switchBackCallback: () => {
      logRedirectToPreviousDomain();
      history.back();
    },
  });
};

const displayNotificationAboutRedirect = ({
  switchBackCallback,
}: {
  switchBackCallback: () => void;
}): void =>
  window.ACP?.container?.toast.show({
    severity: ToastSeverity.info,
    timeout: 5000,
    closable: true,
    message: t('ALTERNATE_DOMAIN_SWITCHING.REDIRECT_MESSAGE'),
    actions: [
      {
        actionName: t('ALTERNATE_DOMAIN_SWITCHING.SWITCH_BACK'),
        onClickCallback: switchBackCallback,
      },
    ],
  });
