import { GlobalSearchMenuElementItem } from 'frontend-container/components/Menu/components/GlobalSearchModal/types';
import {
  ACTIVITIES_RESERVATIONS_DASHBOARD_LINK,
  ACTIVITY_RESERVATIONS_ICON,
  ACTIVITY_RESERVATIONS_TITLE,
} from 'frontend-container/components/Menu/configuration/propertyModules/activityReservations';
import {
  CASHIERING_DASHBOARD_LINK,
  cashieringMenu,
} from 'frontend-container/components/Menu/configuration/propertyModules/cashiering/cashiering';
import {
  HOUSEKEEPING_DASHBOARD_LINK,
  HOUSEKEEPING_ICON,
  HOUSEKEEPING_MENU_TITLE,
} from 'frontend-container/components/Menu/configuration/propertyModules/housekeeping';
import {
  PROFILES_DASHBOARD_LINK,
  profilesMenu,
} from 'frontend-container/components/Menu/configuration/propertyModules/profiles';
import { reportsDashboardLink } from 'frontend-container/components/Menu/configuration/propertyModules/reports';
import {
  FRONT_DESK_DASHBOARD_LINK,
  frontDeskMenu,
  HOUSE_STATUS_LINK,
  INDIVIDUAL_RESERVATIONS_FRONT_DESK_DASHBOARD_LINK,
} from 'frontend-container/components/Menu/configuration/propertyModules/reservations/frontdesk';
import {
  AVAILABILITY_DETAILED_LINK,
  CREATE_RESERVATION_LINK,
  INDIVIDUAL_RESERVATIONS_DASHBOARD_LINK,
  RESERVATIONS_AVAILABILITY_LINK,
  RESERVATIONS_DASHBOARD_LINK,
  reservationsMenu,
} from 'frontend-container/components/Menu/configuration/propertyModules/reservations/reservations';
import { GROUPS_DASHBOARD_LINK } from 'frontend-container/components/Menu/configuration/propertyModules/reservationsGroups';

import { FIQLOperators } from '@ac/library-api';

export const GLOBAL_SEARCH_MODAL_ID = 'global-search-modal-content';
export const OLD_RESERVATIONS_QUERY_PARAM = 'serializedKeyValuesFilters';
export const GLOBAL_SEARCH_HOTKEY_MAC = '⌘ /';
export const GLOBAL_SEARCH_HOTKEY_WINDOWS = 'Ctrl+/';

export const GLOBAL_SEARCH_RECENT_STORAGE_KEY =
  'global-search-recent-storage-key';

export const getSearchForNumberOptions = (
  isEmberToReactEnabled: boolean | undefined,
  isEmberToReactFrontDeskDashboardEnabled: boolean | undefined
): GlobalSearchMenuElementItem[] => [
  {
    id: 'search-for-number-front-desk-dashboard',
    adjustedId: 'search-for-number-front-desk-dashboard',
    icon: frontDeskMenu.icon,
    translation: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.IN_FRONT_DESK_DASHBOARD',
    badgeLabel: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.ROOM',
    link: isEmberToReactFrontDeskDashboardEnabled
      ? INDIVIDUAL_RESERVATIONS_FRONT_DESK_DASHBOARD_LINK
      : FRONT_DESK_DASHBOARD_LINK,
    parentName: frontDeskMenu.translation,
    queryParams: {
      filterKey: 'roomNumber',
      isReservationCustomParam: true,
    },
  },
  {
    id: 'search-for-number-reservations-dashboard',
    adjustedId: 'search-for-number-reservations-dashboard',
    icon: reservationsMenu.icon,
    translation: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.IN_RESERVATION_DASHBOARD',
    badgeLabel: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.CONFIRMATION',
    link: isEmberToReactEnabled
      ? INDIVIDUAL_RESERVATIONS_DASHBOARD_LINK
      : RESERVATIONS_DASHBOARD_LINK,
    parentName: reservationsMenu.translation,
    queryParams: {
      filterKey: isEmberToReactEnabled ? undefined : 'confirmationNumber',
      isReservationCustomParam: !isEmberToReactEnabled,
      queryKey: isEmberToReactEnabled ? 'confirmationNumber' : undefined,
      underscore: isEmberToReactEnabled,
    },
  },
  {
    id: 'search-for-number-housekeeping-dashboard',
    adjustedId: 'search-for-number-housekeeping-dashboard',
    icon: HOUSEKEEPING_ICON,
    translation: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.IN_HOUSEKEEPING_DASHBOARD',
    badgeLabel: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.ROOM',
    link: HOUSEKEEPING_DASHBOARD_LINK,
    parentName: HOUSEKEEPING_MENU_TITLE,
    queryParams: {
      filterKey: 'roomNumbers',
    },
  },
  {
    id: 'search-for-number-cashiering-dashboard',
    adjustedId: 'search-for-number-cashiering-dashboard',
    icon: cashieringMenu.icon,
    translation: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.IN_CASHIERING_DASHBOARD',
    badgeLabel: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.ROOM',
    link: CASHIERING_DASHBOARD_LINK,
    parentName: cashieringMenu.translation,
    queryParams: {
      filterKey: 'reservationDetails.roomNumber',
      operator: FIQLOperators.equal,
      underscore: true,
    },
  },
];

export const getSearchForOptions = (
  isEmberToReactEnabled: boolean | undefined,
  isEmberToReactFrontDeskDashboardEnabled: boolean | undefined
): GlobalSearchMenuElementItem[] => [
  {
    id: 'search-for-reservations-dashboard',
    adjustedId: 'search-for-reservations-dashboard',
    icon: reservationsMenu.icon,
    translation: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.IN_RESERVATION_DASHBOARD',
    link: isEmberToReactEnabled
      ? INDIVIDUAL_RESERVATIONS_DASHBOARD_LINK
      : RESERVATIONS_DASHBOARD_LINK,
    parentName: reservationsMenu.translation,
    queryParams: {
      queryKey: 'query',
      underscore: isEmberToReactEnabled,
    },
  },
  {
    id: 'search-for-front-desk-dashboard',
    adjustedId: 'search-for-front-desk-dashboard',
    icon: frontDeskMenu.icon,
    translation: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.IN_FRONT_DESK_DASHBOARD',
    link: isEmberToReactFrontDeskDashboardEnabled
      ? INDIVIDUAL_RESERVATIONS_FRONT_DESK_DASHBOARD_LINK
      : FRONT_DESK_DASHBOARD_LINK,
    parentName: frontDeskMenu.translation,
    queryParams: {
      queryKey: 'query',
    },
  },
  {
    id: 'search-for-cashiering-dashboard',
    adjustedId: 'search-for-cashiering-dashboard',
    icon: cashieringMenu.icon,
    translation: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.IN_CASHIERING_DASHBOARD',
    link: CASHIERING_DASHBOARD_LINK,
    parentName: cashieringMenu.translation,
    queryParams: {
      queryKey: 'query',
      underscore: true,
    },
  },
  {
    id: 'search-for-housekeeping-dashboard',
    adjustedId: 'search-for-housekeeping-dashboard',
    icon: HOUSEKEEPING_ICON,
    translation: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.IN_HOUSEKEEPING_DASHBOARD',
    link: HOUSEKEEPING_DASHBOARD_LINK,
    parentName: HOUSEKEEPING_MENU_TITLE,
    queryParams: {
      filterKey: 'fts',
    },
  },
  {
    id: 'search-for-activities-dashboard',
    adjustedId: 'search-for-activities-dashboard',
    icon: ACTIVITY_RESERVATIONS_ICON,
    translation: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.IN_ACTIVITIES_DASHBOARD',
    link: ACTIVITIES_RESERVATIONS_DASHBOARD_LINK,
    parentName: ACTIVITY_RESERVATIONS_TITLE,
    queryParams: {
      queryKey: 'fullTextSearch',
    },
  },
  {
    id: 'search-for-profiles-dashboard',
    adjustedId: 'search-for-profiles-dashboard',
    icon: profilesMenu.icon,
    translation: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.IN_PROFILE_DASHBOARD',
    link: PROFILES_DASHBOARD_LINK,
    parentName: profilesMenu.translation,
    queryParams: {
      queryKey: 'query',
    },
  },
];

export const popularLinks: string[] = [
  FRONT_DESK_DASHBOARD_LINK,
  RESERVATIONS_DASHBOARD_LINK,
  INDIVIDUAL_RESERVATIONS_DASHBOARD_LINK,
  CASHIERING_DASHBOARD_LINK,
  GROUPS_DASHBOARD_LINK,
  reportsDashboardLink,
  HOUSEKEEPING_DASHBOARD_LINK,
  RESERVATIONS_AVAILABILITY_LINK,
  CREATE_RESERVATION_LINK,
  HOUSE_STATUS_LINK,
  AVAILABILITY_DETAILED_LINK,
];
