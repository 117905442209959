import {
  MenuElement,
  MenuElementItem,
  Workspace,
} from 'frontend-container/components/Menu/types';

import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const CUSTOMERS_MENU_ID = 'menu-organization-structure-customers';

export const customersDashboard: MenuElementItem = {
  link: `${acConfig.newFrontendUrls.configurationv2}/customers`,
  aliases: [acConfig.newFrontendUrls.configurationv2],
  id: `${CUSTOMERS_MENU_ID}-customers`,
  translation: 'MENU.CUSTOMERS.ITEMS.DASHBOARD',
};

export const customersMenuReadOnlyMode: MenuElement = {
  workspaceType: () => Workspace.NONE,
  translation: 'MENU.CUSTOMERS.TITLE',
  icon: IconName.profile,
  id: `${CUSTOMERS_MENU_ID}-read-only`,
  items: [customersDashboard],
};
export const customersMenuFull: MenuElement = {
  ...customersMenuReadOnlyMode,
  id: CUSTOMERS_MENU_ID,
  items: [
    ...customersMenuReadOnlyMode.items,
    {
      link: `${acConfig.newFrontendUrls.configurationv2}/customers/new`,
      id: `${CUSTOMERS_MENU_ID}-new-customer`,
      translation: 'MENU.CUSTOMERS.ITEMS.NEW',
    },
  ],
};
