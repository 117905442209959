import { getIsMenuElementItemActive } from 'frontend-container/components/Menu/components/ContextSelectButton/getIsMenuElementItemActive';
import { SubItem } from 'frontend-container/components/Menu/components/Item/SubItem/SubItem';
import { SafeFloatingWrapper } from 'frontend-container/components/Menu/components/SafeFloatingWrapper/SafeFloatingWrapper';
import { WorkspaceItem } from 'frontend-container/components/Menu/hooks/useWorkspaceMenuItems';
import { MenuElementItem } from 'frontend-container/components/Menu/types';

import { FlexGap } from '@ac/web-components';

interface Props {
  button: WorkspaceItem;
  currentButtonOption: WorkspaceItem;
  handleOnSubItemClick: (
    link: string,
    menuElementItems?: MenuElementItem[],
    onBeforeRedirectCallback?: () => void
  ) => Promise<void>;
  handleOnMouseOverItem: (label: string) => void;
}

export const ContextSelectButtonContent = ({
  button,
  currentButtonOption,
  handleOnSubItemClick,
  handleOnMouseOverItem,
}: Props): JSX.Element => {
  const isButtonWithSubItems = Boolean(button.subMenuElements?.length);
  const buttonId = `context-select-button-item-${button.id}`;

  return (
    <SafeFloatingWrapper
      key={buttonId}
      nested
      placement="right-start"
      floatingContent={
        <div className="menu-subitems-container menu-subitems-container-dynamic">
          {button.subMenuElements?.map((item) => (
            <SubItem
              item={item}
              key={item.link}
              active={getIsMenuElementItemActive(item)}
              onClick={handleOnSubItemClick}
            />
          ))}
        </div>
      }
    >
      <div>
        <SubItem
          item={{
            link: button.url,
            translation: button.translation,
            id: button.id,
          }}
          active={currentButtonOption.id === button.id}
          onClick={button.onClick}
          onMouseOver={(): void => handleOnMouseOverItem(button.id)}
          showActionArrow={isButtonWithSubItems}
          spacing={FlexGap.sm}
          badgeContent={
            currentButtonOption.id !== button.id ? button.unitName : undefined
          }
        />
      </div>
    </SafeFloatingWrapper>
  );
};
