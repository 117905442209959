import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { fetchWorkstations } from 'frontend-container/components/Menu/components/Cashier/service';
import { getDefaultWorkstation } from 'frontend-container/utils/defaultWorkstation';
import { getUILanguage } from 'frontend-container/utils/UILanguage';

import {
  buildFIQLFilter,
  combineFilters,
  Device,
  DeviceType,
  FIQLOperators,
  GenericEntity,
  PageResponse,
  RawDevice,
  RawWorkstationDeviceLink,
  WorkstationDeviceLink,
} from '@ac/library-api';
import {
  DeviceApi,
  WorkstationDeviceLinkApi,
} from '@ac/library-api/dist/api/v0/configuration/genericEntities';

interface GetLinkDefaultDeviceByDeviceTypeParams {
  workstationDeviceLinks: WorkstationDeviceLink[];
  devices: Device[];
  deviceType: DeviceType;
}

export interface WorkstationsDetails {
  loadingWorkstationDetails: boolean;
  workstation?: GenericEntity;
  setWorkstation: Dispatch<SetStateAction<GenericEntity | undefined>>;
  setupWorkstation: () => void;
  workstations: GenericEntity[];
  keyEncoder?: Device;
  paymentDevice?: Device;
  passportScanner?: Device;
  printer?: Device;
  eRegistrationDevice?: Device;
}

export const useWorkstations = (): WorkstationsDetails => {
  const [loadingWorkstationDetails, setLoadingWorkstationDetails] =
    useState(false);
  const [workstations, setWorkstations] = useState<GenericEntity[]>([]);
  const [workstation, setWorkstation] = useState<GenericEntity | undefined>(
    undefined
  );
  const [keyEncoder, setKeyEncoder] = useState<Device | undefined>(undefined);
  const [paymentDevice, setPaymentDevice] = useState<Device | undefined>(
    undefined
  );
  const [passportScanner, setPassportScanner] = useState<Device | undefined>(
    undefined
  );
  const [printer, setPrinter] = useState<Device | undefined>(undefined);
  const [eRegistrationDevice, setERegistrationDevice] = useState<
    Device | undefined
  >(undefined);
  const language = getUILanguage();

  useEffect(() => {
    const setup = async (): Promise<void> => {
      const fetchedWorkstations = await fetchWorkstations();
      setWorkstations(
        fetchedWorkstations.filter(
          (fetchedWorkstation) => fetchedWorkstation.isActive
        )
      );
      setupWorkstation();
    };
    setup();
  }, [language]);

  const setupWorkstation = (): void => {
    const initialWorkstation = getDefaultWorkstation();
    setWorkstation(
      initialWorkstation ? new GenericEntity(initialWorkstation) : undefined
    );
  };

  useEffect(() => {
    const loadDictionaries = async (): Promise<void> => {
      setLoadingWorkstationDetails(true);
      try {
        const loadedLinks = (await WorkstationDeviceLinkApi.getList({
          queryParams: {
            filter: combineFilters([
              buildFIQLFilter(
                'workstationId',
                FIQLOperators.equal,
                workstation?.id
              ),
              buildFIQLFilter('isActive', FIQLOperators.equal, true),
            ]),
            sort: 'displaySequence',
          },
        })) as PageResponse<RawWorkstationDeviceLink, WorkstationDeviceLink>;

        let loadedDevices: { results: Device[] } = { results: [] };
        if (!loadedLinks.results.length) {
          setKeyEncoder(undefined);
          setPaymentDevice(undefined);
          setPassportScanner(undefined);
          setPrinter(undefined);
          setERegistrationDevice(undefined);

          return;
        }

        loadedDevices = (await DeviceApi.getList({
          queryParams: {
            filter: combineFilters([
              buildFIQLFilter(
                'id',
                FIQLOperators.in,
                loadedLinks.results.map((link) => link.deviceId)
              ),
              buildFIQLFilter('isActive', FIQLOperators.equal, true),
            ]),
            sort: 'displaySequence',
          },
        })) as PageResponse<RawDevice, Device>;
        setKeyEncoder(
          getDefaultDeviceByDeviceType({
            workstationDeviceLinks: loadedLinks.results,
            devices: loadedDevices.results,
            deviceType: DeviceType.KeyEncoder,
          })
        );
        setPaymentDevice(
          getDefaultDeviceByDeviceType({
            workstationDeviceLinks: loadedLinks.results,
            devices: loadedDevices.results,
            deviceType: DeviceType.PaymentDevice,
          })
        );
        setPassportScanner(
          getDefaultDeviceByDeviceType({
            workstationDeviceLinks: loadedLinks.results,
            devices: loadedDevices.results,
            deviceType: DeviceType.PassportScanner,
          })
        );
        setPrinter(
          getDefaultDeviceByDeviceType({
            workstationDeviceLinks: loadedLinks.results,
            devices: loadedDevices.results,
            deviceType: DeviceType.Printer,
          })
        );
        setERegistrationDevice(
          getDefaultDeviceByDeviceType({
            workstationDeviceLinks: loadedLinks.results,
            devices: loadedDevices.results,
            deviceType: DeviceType.ElectronicRegistration,
          })
        );
      } finally {
        setLoadingWorkstationDetails(false);
      }
    };

    if (workstation?.id) {
      loadDictionaries();
    }
  }, [workstation?.id]);

  const getDefaultDeviceByDeviceType = (
    params: GetLinkDefaultDeviceByDeviceTypeParams
  ): Device | undefined => {
    const { workstationDeviceLinks, devices, deviceType } = params;
    const filteredDevicesByDeviceType = devices.filter(
      (device) => device.deviceType === deviceType
    );
    const defaultWorkstationDeviceLink = workstationDeviceLinks.find(
      (workstationDeviceLink) =>
        filteredDevicesByDeviceType
          ?.map((device) => device.id)
          .includes(workstationDeviceLink.deviceId)
    );

    return devices.find(
      (device) => device.id === defaultWorkstationDeviceLink?.deviceId
    );
  };

  return {
    loadingWorkstationDetails,
    workstation,
    setWorkstation,
    setupWorkstation,
    workstations,
    keyEncoder,
    paymentDevice,
    passportScanner,
    printer,
    eRegistrationDevice,
  };
};
