import { getPageIdentifier } from 'frontend-container/utils/activityTracker/getPageIdentifier';
import {
  ActivityTrackerCommand,
  ActivityTrackerData,
  ActivityTrackerRequestData,
} from 'frontend-container/utils/activityTracker/types';
import { getDefaultWorkstationId } from 'frontend-container/utils/defaultWorkstation';
import { addRefreshTokenListener } from 'frontend-container/utils/loginService/loginServiceInitializers';
import debounce from 'lodash.debounce';

import { acConfig } from '@ac/library-utils/dist/declarations';
import { LoginService, SessionService } from '@ac/library-utils/dist/services';

type ActivityTrackerWorker = Omit<Worker, 'postMessage'> & {
  postMessage: (message: ActivityTrackerData) => void;
};

const worker: ActivityTrackerWorker = new Worker(
  /* webpackChunkName: "activityTrackerWorker" */ new URL(
    './activityTracker.worker.ts',
    import.meta.url
  )
);

export const startNewPageActivityTracker = (pageIdentifier: string): void => {
  debouncedSendNewPage.cancel();

  sendNewPageActivityTracker(pageIdentifier);
};

const getRequestInfoData = (): ActivityTrackerRequestData | undefined => {
  const accessToken = LoginService.accessToken();
  const authData = LoginService.authData();
  if (!accessToken || !authData) {
    return;
  }

  return {
    accessToken,
    apiUrl: acConfig.apiUrl,
    expiresAt: authData.expiresAt,
    identityServerOffset: LoginService.getIdentityServerTimeOffset(),
    tenantId: authData.tenantId,
    workstationId: getDefaultWorkstationId(),
    propertyId: SessionService.getPropertyId(),
  };
};

const sendNewPageActivityTracker = (pageIdentifier: string): void => {
  const requestInfo = getRequestInfoData();
  const isPageIdentifierDefined = pageIdentifier !== undefined;
  if (!requestInfo || !isPageIdentifierDefined) {
    return;
  }

  worker.postMessage({
    command: ActivityTrackerCommand.NewPage,
    pageIdentifier,
    ...requestInfo,
  });
};

export const stopPageActivityTracker = (pageIdentifier: string): void => {
  const isPageIdentifierDefined = pageIdentifier !== undefined;
  if (!isPageIdentifierDefined) {
    return;
  }

  worker.postMessage({
    command: ActivityTrackerCommand.Stop,
    pageIdentifier,
  });
};

export const updateActivityTrackerWorkerData = (): void => {
  const requestInfo = getRequestInfoData();
  if (!requestInfo) {
    return;
  }

  worker.postMessage({
    command: ActivityTrackerCommand.RequestInfo,
    ...requestInfo,
  });
};

const debouncedSendNewPage = debounce(sendNewPageActivityTracker, 500);

const initActivityTracker = (): void => {
  addRefreshTokenListener(updateActivityTrackerWorkerData);

  window.addEventListener('single-spa:routing-event', () => {
    debouncedSendNewPage(getPageIdentifier());
  });
  window.addEventListener('pagehide', () => {
    stopPageActivityTracker(getPageIdentifier());
  });
};

export { initActivityTracker };
