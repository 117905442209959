import { Context } from 'frontend-container/components/Menu/components/Context/context';

import { getCurrentRegionCode } from '@ac/library-utils/dist/utils/multi-region';

export const isContextRegionNonCurrent = (context: Context) => {
  return (
    context.regionCode &&
    !compareRegionsCode(context.regionCode, getCurrentRegionCode())
  );
};

const compareRegionsCode = (
  regionCode1: string,
  regionCode2: string
): boolean => {
  return regionCode1.toLowerCase() === regionCode2.toLowerCase();
};
