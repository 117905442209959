import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/utils/isMenuV2Enabled';

import {
  AlignItems,
  FlexDirection,
  TextColor,
  TextWeight,
} from '@ac/web-components';

import { BusinessDate } from './BusinessDate';

import './ContextDropdown.scss';

interface Props {
  businessDate?: string;
  dateFormat?: string;
  propertyTime?: string;
  textColor?: TextColor;
  code: string;
}

export const ContextDropdownDescription = ({
  businessDate,
  dateFormat,
  propertyTime,
  textColor,
  code,
}: Props): JSX.Element => {
  const isMenuV2Enabled = getIsMenuV2Enabled();

  return (
    <ac-flex
      class="context-dropdown-text"
      direction={FlexDirection.column}
      alignItems={AlignItems.flexStart}
    >
      <ac-text
        uppercase
        class="no-wrap context-dropdown-code"
        color={isMenuV2Enabled ? TextColor.foregroundPrimaryStrong : textColor}
        weight={TextWeight.semibold}
      >
        {code}
      </ac-text>
      {businessDate && propertyTime && (
        <BusinessDate
          businessDate={businessDate}
          dateFormat={dateFormat}
          propertyTime={propertyTime}
          color={isMenuV2Enabled ? TextColor.foregroundPrimary : textColor}
        />
      )}
    </ac-flex>
  );
};
