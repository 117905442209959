import { useEffect, useState } from 'react';
import {
  Branding,
  loadBranding,
} from 'frontend-container/shared/branding/loadBranding';
import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';

import { globalBusinessContextUpdatedEventCreator } from '@ac/library-api';

export const useBranding = (): Branding | undefined => {
  const [branding, setBranding] = useState<Branding | undefined>();

  useEffect(() => {
    const eventBus = getCurrentGlobalEventBus();

    const load = async (): Promise<void> => {
      const loadBrandingResult = await loadBranding();

      if (loadBrandingResult) {
        setBranding(loadBrandingResult);
      }
    };

    load();

    const unsubscribeBusinessContextUpdatedEvent = eventBus.subscribe(
      globalBusinessContextUpdatedEventCreator,
      load
    );

    return (): void => unsubscribeBusinessContextUpdatedEvent();
  }, []);

  return branding;
};
