import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import {
  BreadcrumbsButton,
  BreadcrumbsButtonType,
} from 'frontend-container/components/Menu/components/Breadcrumbs/BreadcrumbsButton';
import { BreadcrumbsMobileView } from 'frontend-container/components/Menu/components/Breadcrumbs/BreadcrumbsMobileView';
import { useBreadCrumbsOptionalSegmentDictionary } from 'frontend-container/components/Menu/components/Breadcrumbs/useBreadCrumbsOptionalSegmentDictionary';
import { ContextType } from 'frontend-container/components/Menu/components/Context';
import { ContextSelectButton } from 'frontend-container/components/Menu/components/ContextSelectButton/ContextSelectButton';
import { getAllowedCurrentWorkspaceMenuElements } from 'frontend-container/components/Menu/configuration';
import { getMenuElementsWithBusinessOrderFlat } from 'frontend-container/components/Menu/configuration/utils/getMenuElementsWithBusinessOrder';
import { WorkspaceItem } from 'frontend-container/components/Menu/hooks/useWorkspaceMenuItems';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { getElementsWithSeparateExternalLinks } from 'frontend-container/components/Menu/utils/getElementsWithSeparateExternalLinks';
import { useRouterContext } from 'frontend-container/components/Router/context/context';

import {
  ResponsiveViewport,
  useViewportContext,
  ViewBreakpoint,
} from '@ac/react-infrastructure';

export const breadcrumbsUnitItemId = 'breadcrumbs-unit-button';

interface Props {
  menuItems: MenuElement[];
  buttonSelectOptions?: WorkspaceItem[];
  currentButtonOption?: WorkspaceItem;
  currentModule?: MenuElement;
  allAccessConfiguration?: AllAccessConfiguration;
}

const filterBreadcrumbsMenuItems = (
  menuElements: MenuElement[],
  allAccessConfiguration?: AllAccessConfiguration
): MenuElement[] => {
  if (!allAccessConfiguration) {
    return menuElements;
  }

  return getAllowedCurrentWorkspaceMenuElements(allAccessConfiguration);
};

export const Breadcrumbs = ({
  menuItems,
  buttonSelectOptions,
  currentButtonOption,
  currentModule,
  allAccessConfiguration,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const allAllowedMenuItems = getElementsWithSeparateExternalLinks(
    filterBreadcrumbsMenuItems(menuItems, allAccessConfiguration)
  );

  const unitDetails = useRouterContext((store) => store.unitDetails);
  const unit = useRouterContext((store) => store.currentUnit);

  const sortedMenuItems = useMemo((): MenuElement[] => {
    return getMenuElementsWithBusinessOrderFlat(allAllowedMenuItems);
  }, [allAllowedMenuItems]);

  const {
    state: { responsiveBreakpoint },
  } = useViewportContext();

  const isMobileMenuHidden = responsiveBreakpoint > ViewBreakpoint.SM;
  const menuItemTranslation = t(currentModule?.translation ?? '');

  const isContextButtonVisible =
    buttonSelectOptions && buttonSelectOptions.length >= 2;

  const isUnitBreadcrumbVisible = useMemo((): boolean => {
    switch (unit?.type) {
      case ContextType.PROPERTY: {
        return !unitDetails?.isSinglePropertyUser;
      }
      case ContextType.CRO: {
        return !unitDetails?.isSingleCroUser;
      }
      case ContextType.PROFILE_CENTER: {
        return !unitDetails?.isSingleProfileCenterUser;
      }

      default:
        return false;
    }
  }, [
    unit?.type,
    unitDetails?.isSinglePropertyUser,
    unitDetails?.isSingleCroUser,
    unitDetails?.isSingleProfileCenterUser,
  ]);

  const pathname = window.location.pathname;
  const search = window.location.search;
  const optionalSegment = useBreadCrumbsOptionalSegmentDictionary(
    pathname,
    search
  );

  const propertyCurrentModule =
    currentModule && unit?.type === ContextType.PROPERTY
      ? currentModule
      : undefined;

  return (
    <ResponsiveViewport>
      <ContextSelectButton
        buttonSelectOptions={buttonSelectOptions}
        currentButtonOption={currentButtonOption}
        showDivider={false}
        hideIcon
      />

      {!isMobileMenuHidden && (
        <BreadcrumbsMobileView
          isUnitBreadcrumbVisible={isUnitBreadcrumbVisible}
          menuItemName={menuItemTranslation}
          unitCode={unit?.code}
          menuItems={sortedMenuItems}
          subItems={currentModule?.items ?? []}
        />
      )}

      {isMobileMenuHidden && (
        <Fragment>
          {isUnitBreadcrumbVisible && (
            <BreadcrumbsButton
              content={unit?.code}
              showIcon={isContextButtonVisible}
              type={BreadcrumbsButtonType.context}
              id={breadcrumbsUnitItemId}
            />
          )}
          {menuItemTranslation && (
            <BreadcrumbsButton
              id="breadcrumbs-module-button"
              content={menuItemTranslation}
              showIcon={isContextButtonVisible || isUnitBreadcrumbVisible}
              type={BreadcrumbsButtonType.module}
              menuItems={sortedMenuItems}
              currentModule={propertyCurrentModule}
            />
          )}
        </Fragment>
      )}

      {optionalSegment && (
        <BreadcrumbsButton
          id="breadcrumbs-single-button"
          content={t(optionalSegment.title)}
          transparent={true}
          optionalLink={optionalSegment.link}
        />
      )}
    </ResponsiveViewport>
  );
};
