import { useEffect, useMemo, useRef, useState } from 'react';
import { pathname403 } from 'frontend-container/components/Errors/Error403';
import {
  isCroLandingPage,
  isProfileCenterLandingPage,
  isPropertyLandingPage,
} from 'frontend-container/components/LandingPage/location';
import { getAllAccessConfiguration } from 'frontend-container/components/Menu/authorization/accessConfiguration';
import { getIsMenuComponentAllowed } from 'frontend-container/components/Menu/authorization/getAllowedMenuItems';
import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import { Logo } from 'frontend-container/components/Menu/components/Logo';
import { MenuContent } from 'frontend-container/components/Menu/components/MenuContent/MenuContent';
import {
  getAllowedCentralReservationOfficeMenu,
  getAllowedChangelogMenu,
  getAllowedConfigurationMenu,
  getAllowedMainApplicationMenu,
  getAllowedProfileCenterMenu,
  getAllowedSystemUserMenu,
  getAllowedWorkflowsMenu,
  getFullMenu,
} from 'frontend-container/components/Menu/configuration';
import { useWorkspaceMenuItems } from 'frontend-container/components/Menu/hooks/useWorkspaceMenuItems';
import { findSelectedMenuElement } from 'frontend-container/components/Menu/menuItems';
import { isBusinessContextDataGoingToChange } from 'frontend-container/components/Menu/service';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { useOverrideMenuHeight } from 'frontend-container/components/Menu/useOverrideMenuHeight';
import { useTrainingFeature } from 'frontend-container/components/Menu/useTrainingFeature';
import { getElementsWithSeparateExternalLinks } from 'frontend-container/components/Menu/utils/getElementsWithSeparateExternalLinks';
import { isErrorPage } from 'frontend-container/components/Menu/utils/isErrorPage';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/utils/isMenuV2Enabled';
import { isModuleWithoutMainMenuEntry } from 'frontend-container/components/Menu/utils/isModuleWithoutMainMenuEntry';
import { isPathnameWithoutProperty } from 'frontend-container/components/Menu/utils/isPathnameWithoutProperty';
import {
  adjustScrollToCurrentMenuItem,
  getNextScrollIndex,
} from 'frontend-container/components/Menu/utils/menuScroll/adjustScrollToCurrentMenuItem';
import { setCurrentIndex } from 'frontend-container/components/Menu/utils/menuScroll/currentIndex';
import { getScrollReport } from 'frontend-container/components/Menu/utils/menuScroll/scrollReport';
import { scrollToMenuItem } from 'frontend-container/components/Menu/utils/menuScroll/scrollToSelectedMenuItem';
import { isCentralReservationOfficeModule } from 'frontend-container/components/Menu/utils/modules/centralReservationOffice';
import { isChangelogModule } from 'frontend-container/components/Menu/utils/modules/changelog';
import { isConfigurationModule } from 'frontend-container/components/Menu/utils/modules/configuration';
import { isConfigurationv2TenantContextModule } from 'frontend-container/components/Menu/utils/modules/configurationv2';
import { isFloorPlanModule } from 'frontend-container/components/Menu/utils/modules/floorPlan';
import { isIntegrationsModule } from 'frontend-container/components/Menu/utils/modules/integrations';
import {
  isNotificationsTenantContextDashboard,
  isNotificationSubscriptions,
} from 'frontend-container/components/Menu/utils/modules/notifications';
import { isProfileCentersModule } from 'frontend-container/components/Menu/utils/modules/profileCenters';
import { isWorkflowsModule } from 'frontend-container/components/Menu/utils/modules/workflows';
import { isReadOnlyRequired } from 'frontend-container/components/ReadOnlyMode/isReadOnlyRequired';
import {
  getReadOnlyByUser,
  setReadOnlyMode,
} from 'frontend-container/components/ReadOnlyMode/setReadOnlyMode';
import { emberPathNameAppNameMap } from 'frontend-container/config/emberPathnameAppNameMap';
import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';
import { replaceWithPage } from 'frontend-container/shared/navigation/replaceWithPage';
import { getAppScopeFromPathname } from 'frontend-container/utils/getAppScopeFromPathname';
import { useLayoutDirection } from 'frontend-container/utils/hooks/useLayoutDirection';
import { isEmberAppByAppName } from 'frontend-container/utils/isEmberApp';
import { isMac } from 'frontend-container/utils/isMac';
import { isError403IpWhitlelistPage } from 'frontend-container/utils/setupWhitelistingCatcher';
import { unloadApplication } from 'single-spa';

import { globalBusinessContextUpdatedEventCreator } from '@ac/library-api';
import { LoginService } from '@ac/library-utils/dist/services';
import {
  AlignItems,
  FlexDirection,
  JustifyContent,
  KeyboardKey,
} from '@ac/web-components';

import './MenuV1.scss';

let usedApp: string | undefined;
let resizeId: ReturnType<typeof setTimeout> | undefined;

export const Menu = (): JSX.Element => {
  const [menuItems, setMenuItems] = useState<MenuElement[]>([]);
  const [scrollDownDisabled, setScrollDownDisabled] = useState(false);
  const [scrollUpDisabled, setScrollUpDisabled] = useState(false);
  const [selectedItem, setSelectedItem] = useState(() => {
    const { element: initialSelectedElement } =
      findSelectedMenuElement(menuItems);

    return initialSelectedElement?.id;
  });

  const [selectedRoute, setSelectedRoute] = useState(() => {
    const { item: initialSelectedItem } = findSelectedMenuElement(menuItems);

    return initialSelectedItem ? initialSelectedItem.link : undefined;
  });
  const [isGlobalSearchVisible, setIsGlobalSearchVisible] = useState(false);
  const [, updateArrows] = useState(0);

  const { isRtl } = useLayoutDirection();
  const locationRef = useRef<string>(location.pathname);
  const { visibleWorkspaceMenuItems, currentWorkspaceItem } =
    useWorkspaceMenuItems();
  const isNonProduction = useTrainingFeature();
  const fullMenuConfiguration = useMemo(() => getFullMenu(), []);

  const isMenuV2EnabledCached = getIsMenuV2Enabled();

  useEffect(() => {
    void updateRoute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateMenuScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRtl, menuItems]);

  useEffect(() => {
    const eventBus = getCurrentGlobalEventBus();

    window.addEventListener('popstate', updateRoute);
    window.addEventListener('resize', handleResize);
    const unsubscribeBusinessContextUpdatedEvent = eventBus.subscribe(
      globalBusinessContextUpdatedEventCreator,
      () => updateRoute()
    );

    return (): void => {
      window.removeEventListener('popstate', updateRoute);
      window.removeEventListener('resize', handleResize);
      unsubscribeBusinessContextUpdatedEvent();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItems]);

  useEffect(() => {
    window.addEventListener('keydown', toggleGlobalSearchModal);

    return (): void => {
      window.removeEventListener('keydown', toggleGlobalSearchModal);
    };
  });

  const toggleGlobalSearchModal = (event: KeyboardEvent): void => {
    const isInputEvent =
      event.target && !(event.target as HTMLElement).classList.contains('body');

    // block event when some element is focused and global search is closed
    if (isInputEvent && !isGlobalSearchVisible) {
      return;
    }

    const isCommandOrCtrlDown =
      (isMac && event.metaKey) || (!isMac && event.ctrlKey);

    if (isCommandOrCtrlDown && event.key === 'k') {
      event.preventDefault();
      event.stopPropagation();
      setIsGlobalSearchVisible(!isGlobalSearchVisible);
    }

    if (event.key === '/' && !isGlobalSearchVisible) {
      event.preventDefault();
      event.stopPropagation();
      setIsGlobalSearchVisible(true);
    }

    if (event.key === KeyboardKey.Escape) {
      setIsGlobalSearchVisible(false);
    }
  };

  const handleResize = (): void => {
    if (resizeId) {
      clearTimeout(resizeId);
    }
    resizeId = setTimeout(updateMenuScroll, 150);
  };

  const updateMenuScroll = (): void => {
    const currentMenuElements = menuItems;
    const { element } = findSelectedMenuElement(currentMenuElements);
    const id = element?.id ?? selectedItem ?? '';
    if (id) {
      void adjustScrollToCurrentMenuItem(
        id,
        currentMenuElements,
        updateArrowStatus
      );
    }
  };

  const redirectWithoutMenuItemAccess = (
    allAccessConfiguration: AllAccessConfiguration,
    currentMenuElementSelected?: MenuElement
  ): boolean => {
    const isRouteNotFound =
      !currentMenuElementSelected &&
      allAccessConfiguration[allAccessConfiguration.currentSource].permissions
        .length &&
      !isModuleWithoutMainMenuEntry();

    if (isRouteNotFound) {
      replaceWithPage(pathname403);

      return true;
    }

    const { element: selectedMenuElement, item: selectedMenuItem } =
      findSelectedMenuElement(fullMenuConfiguration);

    const isMenuItemNotAllowed =
      selectedMenuItem &&
      !getIsMenuComponentAllowed(selectedMenuItem, allAccessConfiguration);

    const isMenuElementNotAllowed =
      selectedMenuElement &&
      !isModuleWithoutMainMenuEntry() &&
      !getIsMenuComponentAllowed(selectedMenuElement, allAccessConfiguration);

    if (isMenuItemNotAllowed || isMenuElementNotAllowed) {
      replaceWithPage(pathname403);

      return true;
    }

    return false;
  };

  const updateMenuItems = (
    allAccessConfiguration: AllAccessConfiguration
  ): MenuElement[] => {
    if (isErrorPage() && menuItems.length) {
      return menuItems;
    }

    let menuElements: MenuElement[];

    const isSystemUser = LoginService.isSuperUser();

    if (
      isConfigurationModule() ||
      isConfigurationv2TenantContextModule() ||
      isFloorPlanModule() ||
      isNotificationSubscriptions() ||
      isNotificationsTenantContextDashboard()
    ) {
      menuElements = getAllowedConfigurationMenu(allAccessConfiguration);
    } else if (
      isPropertyLandingPage() ||
      isProfileCenterLandingPage() ||
      isCroLandingPage()
    ) {
      menuElements = [];
    } else if (!isSystemUser && isProfileCentersModule()) {
      menuElements = getAllowedProfileCenterMenu(allAccessConfiguration);
    } else if (!isMenuV2EnabledCached && isChangelogModule()) {
      menuElements = getAllowedChangelogMenu(allAccessConfiguration);
    } else if (!isSystemUser && isWorkflowsModule()) {
      menuElements = getAllowedWorkflowsMenu(allAccessConfiguration);
    } else if (!isSystemUser && isCentralReservationOfficeModule()) {
      menuElements = getAllowedCentralReservationOfficeMenu(
        allAccessConfiguration
      );
    } else if (isSystemUser && isIntegrationsModule()) {
      menuElements = getAllowedSystemUserMenu(allAccessConfiguration);
    } else {
      menuElements = getAllowedMainApplicationMenu(allAccessConfiguration);
    }

    const mappedElements = getElementsWithSeparateExternalLinks(menuElements);
    setMenuItems(mappedElements);

    return mappedElements;
  };

  const unloadEmberApplication = async (): Promise<void> => {
    const scope = getAppScopeFromPathname();
    if (
      !!usedApp &&
      emberPathNameAppNameMap[scope] !== usedApp &&
      isEmberAppByAppName(usedApp)
    ) {
      await unloadApplication(usedApp ?? '');
    }
    usedApp = emberPathNameAppNameMap[scope];
  };

  const updateRoute = async (event?: Event): Promise<void> => {
    const isPopstateEvent = event && event.type === 'popstate';
    if (isPopstateEvent) {
      const newPathname = location.pathname;
      if (newPathname === locationRef.current) {
        return;
      }
      locationRef.current = newPathname;
    }

    await unloadEmberApplication();
    // Only modules which are editable in global region care for readOnly mode.
    // Each time we enter into such module, if we are not in global region, we should be in readOnly mode.
    // That's why we active readOnly mode for modules other then the ones editable in global region.
    if (isReadOnlyRequired()) {
      const newValue = getReadOnlyByUser() ?? true;
      setReadOnlyMode(newValue);
    }

    if (isBusinessContextDataGoingToChange()) {
      return;
    }

    const allAccessConfiguration = getAllAccessConfiguration();

    const menuElements: MenuElement[] = updateMenuItems(allAccessConfiguration);

    const { element, item } = findSelectedMenuElement(menuElements);

    const isRedirected = redirectWithoutMenuItemAccess(
      allAccessConfiguration,
      element
    );

    if (isRedirected) {
      return;
    }

    if (element) {
      handleSelectRoute(element?.id ?? '', item?.link);
    }
  };

  const handleSelectRoute = (
    menuElementId: string,
    link: string | undefined
  ): void => {
    setSelectedItem(menuElementId);
    setSelectedRoute(link);
  };

  const onButtonHandleSelectRoute = (
    menuElementId: string,
    link: string | undefined
  ): void => {
    setSelectedItem(menuElementId);
    setSelectedRoute(link);
  };

  const onScrollUp = (): void => {
    onScroll(false);
  };

  const onScrollDown = (): void => {
    onScroll(true);
  };

  const onScroll = (forward: boolean): void => {
    const elements = menuItems;
    const maxIndex = elements.length - 1;
    const nextIndex = getNextScrollIndex(forward, isRtl);
    const limitedIndex = Math.min(Math.max(0, nextIndex), maxIndex);
    const selectedMenuItemId = elements[limitedIndex]?.id;

    if (!selectedMenuItemId) {
      return;
    }

    const hasScrolled = scrollToMenuItem({
      id: selectedMenuItemId,
      center: false,
      enforce: true,
      callback: updateArrowStatus,
    });
    if (hasScrolled) {
      setCurrentIndex(limitedIndex);
    }
  };

  const updateArrowStatus = (scrollOptions: ScrollToOptions): void => {
    const { isArrowUpDisabled, isArrowDownDisabled, isScrollVisible } =
      getScrollReport(scrollOptions);
    if (isScrollVisible) {
      setScrollUpDisabled(isArrowUpDisabled);
      setScrollDownDisabled(isArrowDownDisabled);
    }

    // update only if it's necessary
    updateArrows(
      Number(isArrowUpDisabled) +
        Number(isArrowDownDisabled) * 10 +
        Number(isScrollVisible) * 100
    );
  };

  const menuBarClasses = useMemo(() => {
    const classes = [
      'menu-bar',
      isPathnameWithoutProperty() ? 'without-property' : '',
    ];

    return classes.filter(Boolean).join(' ');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItems.length, window.location.pathname]);

  useOverrideMenuHeight(isNonProduction, isMenuV2EnabledCached);

  return (
    <ac-flex direction={FlexDirection.column}>
      <ac-flex class="layout-direction">
        <>
          <Logo />
          <ac-box class="menu-bar-wrapper">
            <ac-flex
              alignItems={AlignItems.center}
              dynamicClass={menuBarClasses}
              justifyContent={
                isError403IpWhitlelistPage()
                  ? JustifyContent.flexEnd
                  : JustifyContent.normal
              }
              direction={
                isError403IpWhitlelistPage()
                  ? FlexDirection.row
                  : FlexDirection.column
              }
            >
              <MenuContent
                isRtl={isRtl}
                menuItems={menuItems}
                onButtonHandleSelectRoute={onButtonHandleSelectRoute}
                onScrollDown={onScrollDown}
                onScrollUp={onScrollUp}
                scrollDownDisabled={scrollDownDisabled}
                scrollUpDisabled={scrollUpDisabled}
                selectedItem={selectedItem}
                selectedRoute={selectedRoute}
                buttonSelectOptions={visibleWorkspaceMenuItems}
                currentButtonContextOption={currentWorkspaceItem}
              />
            </ac-flex>
          </ac-box>
        </>
      </ac-flex>
    </ac-flex>
  );
};
