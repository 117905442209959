import { HOSTED_ENVANY_DOMAIN } from 'frontend-container/shared/constants';

import { CookiesService, LoginService } from '@ac/library-utils/dist/services';
import { isValidIpAddress } from '@ac/library-utils/dist/utils';
import { getCurrentRegionCode } from '@ac/library-utils/dist/utils/multi-region';

export const setSharedCookie = (
  key: string,
  value: string,
  expirationTime?: number | Date
): void => {
  CookiesService.setCookie(key, value, expirationTime, LoginService.domain());
  // TODO: it is for backward compatibility, should be removed when all FEApps uses improved domain
  if (
    window.location.hostname !== 'localhost' &&
    window.location.hostname !== HOSTED_ENVANY_DOMAIN &&
    !isValidIpAddress(window.location.hostname)
  ) {
    CookiesService.deleteCookie(
      key,
      window.location.hostname.replace(getCurrentRegionCode() ?? '', '')
    );
    CookiesService.deleteCookie(key, window.location.hostname);
  }
};

export const getSharedCookie = (key: string): string | undefined => {
  return CookiesService.getCookie(key);
};

export const deleteSharedCookie = (key: string): void => {
  CookiesService.deleteCookie(key, LoginService.domain());
};
