import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { CashierInfotipBody } from 'frontend-container/components/Menu/components/Cashier/CashierInfotip/CashierInfotipBody';
import { CashierInfotipHeader } from 'frontend-container/components/Menu/components/Cashier/CashierInfotip/CashierInfotipHeader';
import { CashierInfotipTrigger } from 'frontend-container/components/Menu/components/Cashier/CashierInfotip/CashierInfotipTrigger';
import { cashierStatusOpenedEventCreator } from 'frontend-container/components/Menu/components/Cashier/events';
import { ChangeCashierSecretModal } from 'frontend-container/components/Menu/components/Cashier/Modals/ChangeCashierSecretModal/ChangeCashierSecretModal';
import { CloseCashierModal } from 'frontend-container/components/Menu/components/Cashier/Modals/CloseCashierModal/CloseCashierModal';
import { OpenCashierModal } from 'frontend-container/components/Menu/components/Cashier/Modals/OpenCashierModal/OpenCashierModal';
import {
  fetchCashierData,
  fetchCashierInfo,
  fetchIsSecretDefinedState,
  getProcessedCashierPermissions,
} from 'frontend-container/components/Menu/components/Cashier/service';
import { MainMenuInfotip } from 'frontend-container/components/Menu/components/MainMenuInfotip/MainMenuInfotip';
import { userService } from 'frontend-container/components/Menu/components/User/service';
import { CashierInfo } from 'frontend-container/components/Menu/types';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/utils/isMenuV2Enabled';
import { ModalCallback } from 'frontend-container/publicApi/windowObject';
import { getPropertyContextData } from 'frontend-container/shared/businessContext/getBusinessContext';
import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';

import { IEventBus } from '@ac/library-utils/dist/communication/event-bus';
import { SessionService } from '@ac/library-utils/dist/services';
import { isDefined } from '@ac/library-utils/dist/utils';

import './Cashier.scss';

interface CashierInfoWithOpenStatus extends Omit<CashierInfo, 'number'> {
  isCashierOpen: boolean;
  number?: number;
}

interface SetGivenCashierInSessionDataParams {
  isCashierOpen: boolean;
  cashierNumber?: number;
}

const setGivenCashierInSessionData = ({
  isCashierOpen,
  cashierNumber,
}: SetGivenCashierInSessionDataParams): void => {
  const newCashierNumber = isCashierOpen ? cashierNumber : undefined;
  const propertyId = SessionService.getPropertyId();

  if (isCashierOpen) {
    SessionService.setCashierNumber(newCashierNumber);

    if (propertyId) {
      SessionService.setPropertyCashierNumber(propertyId, newCashierNumber);
    }
  } else {
    SessionService.setCashierNumber(undefined);
    SessionService.setPropertyCashierNumber(propertyId, undefined);
  }
};

const menuId = 'menu-cashier';
interface ModalCallbacksObject {
  onCancel?: ModalCallback;
  onSuccess?: ModalCallback;
}

const dispatchCashierStatusOpenedEvent = (
  eventBus: IEventBus,
  cashierNumber: number
): void => {
  const currentUser = userService.getCurrentUserDetails();
  const cashierFullName = [
    currentUser?.personalData?.firstName,
    currentUser?.personalData?.lastName,
  ]
    .filter(isDefined)
    .join(' ');

  eventBus.dispatch(
    cashierStatusOpenedEventCreator({ cashierNumber, cashierFullName })
  );
};

const setCashierNumberSessionData = (
  eventBus: IEventBus,
  isCashierOpen: boolean,
  cashierNumber: number | undefined
): void => {
  setGivenCashierInSessionData({
    isCashierOpen,
    cashierNumber,
  });

  if (cashierNumber && isCashierOpen) {
    dispatchCashierStatusOpenedEvent(eventBus, cashierNumber);
  }
};

const updateCashierInfo = (
  eventBus: IEventBus,
  cashierData: CashierInfoWithOpenStatus
): CashierInfo | undefined => {
  const { isCashierOpen, number, version, name } = cashierData;
  if (!number) {
    return;
  }

  setCashierNumberSessionData(eventBus, isCashierOpen, number);

  return {
    name,
    version,
    number,
  };
};

const getCashierData = async (
  isCashierOpen: boolean,
  cashierNumber: number
): Promise<CashierInfoWithOpenStatus | undefined> => {
  if (!cashierNumber) {
    return;
  }

  const currentCashierInfo = await fetchCashierInfo(cashierNumber);
  const cashierName = `${currentCashierInfo.cashierId} - ${currentCashierInfo.description}`;

  return {
    isCashierOpen,
    number: cashierNumber,
    name: cashierName,
    version: currentCashierInfo.version,
  };
};

export const Cashier: FC = () => {
  const eventBus = useMemo(() => getCurrentGlobalEventBus(), []);

  const [cashierName, setCashierName] = useState<string>();
  const [cashierVersion, setCashierVersion] = useState<number>();
  const [cashierId, setCashierId] = useState<number>();
  const [cashierNumber, setCashierNumber] = useState<number | undefined>(
    undefined
  );
  const [isCashierOpen, setIsCashierOpen] = useState<boolean>(false);
  const [isChangeSecretModalOpen, setIsChangeSecretModalOpen] =
    useState<boolean>(false);
  const [isCloseCashierModalOpen, setIsCloseCashierModalOpen] =
    useState<boolean>(false);
  const [isOpenCashierModalOpen, setIsOpenCashierModalOpen] =
    useState<boolean>(false);
  const [isSecretDefined, setIsSecretDefined] = useState<boolean>(true);

  const permissionIds = getPropertyContextData()?.permissions.permissionIds;

  const processedCashierPermissions = useMemo(
    () => getProcessedCashierPermissions(permissionIds),
    [permissionIds]
  );

  const actions = useRef<ModalCallbacksObject>({
    onCancel: undefined,
    onSuccess: undefined,
  });

  const updateCashierData = async (): Promise<void> => {
    const cashierData = await fetchCashierData();

    setCashierNumber(cashierData.cashierNumber);
    setIsCashierOpen(cashierData.isCashierOpen);
    setGivenCashierInSessionData(cashierData);
  };

  const updateCurrentCashierInfo = async (): Promise<
    CashierInfo | undefined
  > => {
    if (!cashierNumber) {
      return;
    }
    const cashierData = await getCashierData(isCashierOpen, cashierNumber);
    if (isCashierOpen) {
      setCashierName(cashierData?.name);
    } else {
      setCashierName('');
    }
    setCashierVersion(cashierData?.version);
    setCashierId(cashierId);

    return updateCashierInfo(eventBus, {
      isCashierOpen,
      number: cashierData?.number,
      name: cashierData?.name,
      version: cashierData?.version,
    });
  };

  useEffect(() => {
    updateCurrentCashierInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashierNumber, isCashierOpen, cashierName, cashierVersion, cashierId]);

  useEffect(() => {
    if (!processedCashierPermissions.isViewCashierStatusDashboard) {
      return;
    }
    updateCashierData();
    const refreshInterval = setInterval(updateCashierData, 60000);

    return (): void => {
      clearInterval(refreshInterval);
    };
  }, [processedCashierPermissions]);

  useEffect(() => {
    if (window?.ACP?.container) {
      window.ACP.container.openCashierModal = handleOpenCashier;
      window.ACP.container.closeCashierModal = handleCloseCashier;
      window.ACP.container.refreshCashier = updateCashierData;
    }

    return (): void => {
      if (window?.ACP?.container) {
        delete window.ACP.container.openCashierModal;
        delete window.ACP.container.closeCashierModal;
        delete window.ACP.container.refreshCashier;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const executeModalCallback = async (
    isSuccess: boolean,
    data?: unknown
  ): Promise<void> => {
    if (isSuccess) {
      await actions.current.onSuccess?.(data);
    } else {
      await actions.current.onCancel?.(data);
    }

    actions.current = {
      onCancel: undefined,
      onSuccess: undefined,
    };
  };

  const toggleChangeSecretModal = (): void => {
    setIsChangeSecretModalOpen(!isChangeSecretModalOpen);
  };

  const toggleOpenCashierModal = async (): Promise<void> => {
    if (
      !isOpenCashierModalOpen &&
      processedCashierPermissions.isViewCashierStatusDashboard
    ) {
      const isSecretDefinedState = await fetchIsSecretDefinedState();
      if (!isSecretDefinedState) {
        setIsSecretDefined(false);
        await executeModalCallback(false);

        return toggleChangeSecretModal();
      }
    }

    setIsOpenCashierModalOpen(!isOpenCashierModalOpen);
  };

  const toggleCloseCashierModal = (): void => {
    setIsCloseCashierModalOpen(!isCloseCashierModalOpen);
  };

  const handleOpenCashier = (
    onCancel?: ModalCallback<void>,
    onSuccess?: ModalCallback<{ cashierNumber: number; workstationId: string }>
  ): void => {
    actions.current = {
      onCancel,
      onSuccess,
    };

    toggleOpenCashierModal();
  };

  const handleCloseCashier = (
    onCancel?: ModalCallback<void>,
    onSuccess?: ModalCallback<string>
  ): void => {
    actions.current = {
      onCancel,
      onSuccess,
    };

    toggleCloseCashierModal();
  };

  const handleOpenCashierCancel = async (): Promise<void> => {
    await executeModalCallback(false);
    toggleOpenCashierModal();
  };

  const handleCloseCashierConfirm = async (
    locationHeaderValue: string
  ): Promise<void> => {
    setCashierNumber(undefined);
    setIsCashierOpen(false);
    await executeModalCallback(true, locationHeaderValue);
    toggleCloseCashierModal();
    await updateCashierData();
  };

  const handleCloseCashierCancel = async (): Promise<void> => {
    toggleCloseCashierModal();
    await executeModalCallback(false);
  };

  const handleOpenCashierConfirm = async (
    cashierNumberValue: number,
    workstationId: string
  ): Promise<void> => {
    setCashierNumber(cashierNumberValue);
    setIsCashierOpen(true);
    const cashierData = await getCashierData(true, cashierNumberValue);
    updateCashierInfo(eventBus, {
      isCashierOpen: true,
      number: cashierData?.number,
      name: cashierData?.name,
      version: cashierData?.version,
    });
    await executeModalCallback(true, {
      cashierNumber: cashierNumberValue,
      workstationId,
    });
    toggleOpenCashierModal();
  };

  const handleChangeSecret = (): void => {
    toggleChangeSecretModal();
    setIsSecretDefined(true);
  };

  return (
    <>
      <MainMenuInfotip
        componentId={menuId}
        additionalContainerClass={`${
          !getIsMenuV2Enabled() ? 'ac-margin-inline-end-sm' : ''
        } layout-direction`}
        triggeringComponent={
          <CashierInfotipTrigger isCashierOpen={isCashierOpen} />
        }
        headerComponent={<CashierInfotipHeader cashierName={cashierName} />}
        bodyComponent={
          <CashierInfotipBody
            isCashierOpen={isCashierOpen}
            toggleOpenCashierModal={toggleOpenCashierModal}
            toggleChangeSecretModal={toggleChangeSecretModal}
          />
        }
      />
      {isChangeSecretModalOpen && (
        <ChangeCashierSecretModal
          isOpenCashierAllowed={
            processedCashierPermissions.isOpenCashierAllowed
          }
          onClose={toggleChangeSecretModal}
          onConfirm={handleChangeSecret}
          isFirstAttempt={!isSecretDefined}
        />
      )}
      {isOpenCashierModalOpen && (
        <OpenCashierModal
          isOpenCashierAllowed={
            processedCashierPermissions.isOpenCashierAllowed
          }
          isManageExclusiveCashierAllowed={
            processedCashierPermissions.isManageExclusiveCashierAllowed
          }
          onClose={handleOpenCashierCancel}
          onConfirm={handleOpenCashierConfirm}
        />
      )}
      {isCloseCashierModalOpen && (
        <CloseCashierModal
          isCloseCashierAllowed={
            processedCashierPermissions.isCloseCashierAllowed
          }
          onClose={handleCloseCashierCancel}
          onConfirm={updateCurrentCashierInfo}
          onSuccess={handleCloseCashierConfirm}
          cashierName={cashierName}
          cashierNumber={cashierNumber}
        />
      )}
    </>
  );
};
