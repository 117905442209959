import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { PROPERTY_DETAILS_MENU_ID } from 'frontend-container/components/Menu/configuration/tenantModules/configuration/organizationStructure';
import {
  MenuElement,
  Workspace,
} from 'frontend-container/components/Menu/types';

import { customerPermissionKeys } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const propertyDetailsMenu: MenuElement = {
  workspaceType: () => Workspace.CONFIGURATION,
  id: PROPERTY_DETAILS_MENU_ID,
  translation: 'MENU.CONFIGURATION.ITEMS.PROPERTY_DETAILS',
  icon: IconName.configProperty,
  items: [
    {
      link: `${acConfig.newFrontendUrls.configurationv2}/property/property-details`,
      translation: 'MENU.CONFIGURATION.ITEMS.PROPERTY_DETAILS',
      id: PROPERTY_DETAILS_MENU_ID,
      permissionsConfiguration: {
        permissions: [
          {
            key: customerPermissionKeys.configuration
              .accessToConfigurationModule,
            source: AccessSource.Tenant,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      isUnderlined: true,
    },
  ],
};
