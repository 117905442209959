import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import {
  MenuElement,
  Workspace,
} from 'frontend-container/components/Menu/types';
import {
  MAC_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX,
} from 'frontend-container/shared/constants';

import { propertyPermissionKeys } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const INQUIRY_MENU_ID = 'menu-inquiry';

export const inquiryMenu: MenuElement = {
  workspaceType: () => Workspace.PROPERTY,
  translation: 'MENU.INQUIRY.TITLE',
  icon: IconName.calendarPeriod,
  id: INQUIRY_MENU_ID,
  items: [
    {
      link: `${acConfig.newFrontendUrls.reservations}/reservations/inquiry`,
      code: 'InquiryDashboard',
      id: `${INQUIRY_MENU_ID}-dashboard`,
      translation: 'MENU.INQUIRY.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: propertyPermissionKeys.reservations.dashboard
              .viewGeneralInquiry,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}I`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}I`,
      keyCode: 73,
    },
  ],
};
