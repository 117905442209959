import { Workspace } from 'frontend-container/components/Menu/types';
import { isErrorPage } from 'frontend-container/components/Menu/utils/isErrorPage';
import { getSelectedMainMenuItem } from 'frontend-container/components/Router/utils/getSelectedMainMenuItem';
import { getBusinessContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

import { isCentralReservationOfficeBusinessContextData } from '@ac/library-api';

const isCroBusinessContextOnErrorPage = (): boolean => {
  return (
    isErrorPage() &&
    isCentralReservationOfficeBusinessContextData(getBusinessContextData())
  );
};

const pathUtilFunctions = [isCroBusinessContextOnErrorPage];

export const isCroContextVisible = (): boolean => {
  const { element } = getSelectedMainMenuItem();
  const isModuleInCroWorkspace = element?.workspaceType() === Workspace.CRO;

  return isModuleInCroWorkspace || pathUtilFunctions.some((func) => func());
};
