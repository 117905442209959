import { useEffect } from 'react';
import {
  saveLoginBrandingToStorage,
  updateLoginBrandingStorage,
} from 'frontend-container/components/Menu/components/Branding/brandingLocalStorage';
import { updateFavicon } from 'frontend-container/components/Menu/components/Branding/updateFavicon';
import { loadBranding } from 'frontend-container/shared/branding/loadBranding';
import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';

import { globalBusinessContextUpdatedEventCreator } from '@ac/library-api';
import { toDataURL } from '@ac/library-utils/dist/utils';

export const Branding = (): null => {
  useEffect(() => {
    const eventBus = getCurrentGlobalEventBus();

    const updateBranding = async (): Promise<void> => {
      const branding = await loadBranding();

      updateFavicon(branding);

      saveLoginBrandingToStorage({
        loginBackgroundUrl: branding?.loginBackgroundUrl,
        loginLogoUrl: branding?.loginLogoUrl,
        loginBackgroundId: branding?.loginBackgroundId,
        loginLogoId: branding?.loginLogoId,
      });

      try {
        if (branding?.favicon) {
          const loginFaviconData = await toDataURL(branding.favicon);
          updateLoginBrandingStorage({ loginFaviconData });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    updateBranding();

    const unsubscribeBusinessContextUpdatedEvent = eventBus.subscribe(
      globalBusinessContextUpdatedEventCreator,
      updateBranding
    );

    return (): void => {
      unsubscribeBusinessContextUpdatedEvent();
    };
  }, []);

  return null;
};
