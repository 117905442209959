import { GenericEntity } from '@ac/library-api';
import {
  LoginService,
  SelectedWorkstation,
  SelectedWorkstations,
  SessionService,
  WorkstationsService,
  WorkstationType,
} from '@ac/library-utils/dist/services';

export const getDefaultWorkstationId = (): string | undefined => {
  return getDefaultWorkstation()?.id;
};

export const getDefaultWorkstation = (): SelectedWorkstation | undefined => {
  const selectedWorkstations = getSelectedWorkstations() || {};
  const cashierNumber = SessionService.getCashierNumber();
  const userId = LoginService.authData()?.userId;

  if (!userId) {
    return;
  }

  const propertyId = SessionService.getPropertyId();

  const defaultWorkstation = propertyId
    ? selectedWorkstations?.[userId]?.[
        WorkstationsService.buildWorkstationKey({
          propertyId,
          type: WorkstationType.Default,
        })
      ]
    : undefined;

  const cashieringWorkstation =
    propertyId && cashierNumber
      ? selectedWorkstations?.[userId]?.[
          WorkstationsService.buildWorkstationKey({
            propertyId,
            type: WorkstationType.Cashiering,
            cashierNumber,
          })
        ]
      : undefined;

  return cashieringWorkstation || defaultWorkstation;
};

export const setDefaultWorkstation = (
  value?: GenericEntity,
  cashierNumber?: number
): void => {
  const storedCashierNumber = SessionService.getCashierNumber();
  const cashierValue = cashierNumber || storedCashierNumber;

  const selectedWorkstations = getSelectedWorkstations() || {};
  const userId = LoginService.authData()?.userId ?? '';

  const workstationsDraft = {
    ...(selectedWorkstations?.[userId] ?? {}),
    [WorkstationsService.buildWorkstationKey({
      propertyId: SessionService.getPropertyId() ?? '',
      type: WorkstationType.Default,
    })]: value,
  };

  if (cashierValue) {
    const cashieringWorkstationName = WorkstationsService.buildWorkstationKey({
      propertyId: SessionService.getPropertyId() ?? '',
      type: WorkstationType.Cashiering,
      cashierNumber: cashierValue,
    });

    workstationsDraft[cashieringWorkstationName] = value;
  }

  setSelectedWorkstations({
    [userId]: workstationsDraft,
  });
};

const setSelectedWorkstations = (workstations: SelectedWorkstations): void =>
  SessionService.setSelectedWorkstations(workstations);

const getSelectedWorkstations = (): SelectedWorkstations | undefined => {
  try {
    return SessionService.getSelectedWorkstations();
  } catch {
    return undefined;
  }
};
