import {
  getFullMenu,
  getMainApplicationMenu,
} from 'frontend-container/components/Menu/configuration';
import {
  findSelectedMenuElement,
  SelectedMenuElement,
  SelectedMenuElementCache,
} from 'frontend-container/components/Menu/menuItems';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/utils/isMenuV2Enabled';

const selectedMenuElementCache: SelectedMenuElementCache = {
  element: undefined,
  item: undefined,
  pathname: '',
};

export const getSelectedMainMenuItem = (
  customPathname?: string
): SelectedMenuElement => {
  const menuElements = getIsMenuV2Enabled()
    ? getMainApplicationMenu()
    : getFullMenu();
  const pathname = window.location.pathname;

  if (customPathname) {
    return findSelectedMenuElement(menuElements, { customPathname });
  }

  if (
    selectedMenuElementCache.pathname === pathname &&
    selectedMenuElementCache.element
  ) {
    return selectedMenuElementCache;
  }

  return findSelectedMenuElement(menuElements, {
    customPathname,
    cache: selectedMenuElementCache,
  });
};
