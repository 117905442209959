import { getAllAccessConfiguration } from 'frontend-container/components/Menu/authorization/accessConfiguration';
import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import { Context } from 'frontend-container/components/Menu/components/Context';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { UserUnitsDetails } from 'frontend-container/components/Prestep/getUserUnitsDetails';
import { getSelectedWorkspaceUnits } from 'frontend-container/components/Router/utils/getWorkspaceUnits';

export interface RouterState {
  allAccessConfiguration: AllAccessConfiguration;
  locationRef: string;
  userMenuElements: MenuElement[];
  selectedCro?: Context;
  selectedProperty?: Context;
  selectedProfileCenter?: Context;
  currentUnit?: Context;
  unitDetails?: UserUnitsDetails;
}

export const getDefaultRouterState = (): RouterState => {
  const { currentUnit, selectedCro, selectedProfileCenter, selectedProperty } =
    getSelectedWorkspaceUnits();
  const allAccessConfiguration = getAllAccessConfiguration();

  return {
    allAccessConfiguration,
    locationRef: window.location.href,
    userMenuElements: [],
    selectedCro,
    selectedProperty,
    selectedProfileCenter,
    currentUnit,
    unitDetails: undefined,
  };
};
